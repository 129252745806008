import React from "react";
import styled from "styled-components";
import { CharacterDerivedStats, DerivedStatBuffs } from "types";

import { COLORS } from "utils/constants";
import { getDerivedStatData } from "utils/stats";

interface FightBuffsProps {
  name: string | null;
  buffs: DerivedStatBuffs;
}

interface BuffProps {
  isBuff: boolean;
}

interface BuffIconProps {
  isBuff: boolean;
  image: string;
}

const FightBuffsWrapper = styled.div``;

const BuffsTitle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  font-size: 18px;
`;

const Buff = styled.div<BuffProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${(props) => (!!props.isBuff ? COLORS.BUFFS : COLORS.DEBUFFS)};
`;

const BuffIcon = styled.div<BuffIconProps>`
  height: 10px;
  width: 10px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${(props) =>
    !!props.isBuff ? COLORS.BUFFS : COLORS.DEBUFFS};
`;

export default function FightBuffs({ name, buffs }: FightBuffsProps) {
  const activeBuffs = Object.entries(buffs).filter(
    ([key, buffValues]) => buffValues.length > 0
  );

  return (
    <FightBuffsWrapper>
      {activeBuffs.length > 0 && <BuffsTitle>{name}'s Effects</BuffsTitle>}
      {activeBuffs.map(([key, buffValues]) => {
        const statSlug = key as keyof CharacterDerivedStats;
        const statInfo = getDerivedStatData(statSlug);

        const statMultiplier = buffValues.reduce(
          (acc: number, buff: number) => acc * buff,
          1
        );

        const isBuff = statMultiplier >= 1;
        return (
          <Buff key={statSlug} isBuff={isBuff}>
            <BuffIcon image={statInfo.image} isBuff={isBuff} />
            {statInfo.name} Multiplier: {statInfo.buffFormatter(statMultiplier)}
          </Buff>
        );
      })}
    </FightBuffsWrapper>
  );
}
