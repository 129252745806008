import styled, { css } from "styled-components";

import {
  DataWrapper,
  Section,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import SettingsAuth from "components/ui/SettingsAuth";
import SettingsMusic from "./SettingsMusic";

interface SettingOptionProps {
  $isActive: boolean;
}

const Link = styled.a`
  color: white;
`;

export const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SettingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SettingValue = styled.div``;

export const SettingName = styled.div`
  text-transform: uppercase;
  font-weight: 600;
`;

export const SettingOptions = styled.div`
  display: flex;
`;

const activeSettingMixin = css`
  color: #43fffa;
  cursor: auto;
  border-color: #43fffa;
  opacity: 1;
`;

export const SettingOption = styled.div<SettingOptionProps>`
  padding: 5px 20px;
  transition: all 200ms ease-in;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  border: 1px transparent solid;
  opacity: 0.5;
  background-color: #43fffa15;

  ${(props) => props.$isActive && activeSettingMixin}

  :hover {
    opacity: 1;
  }
`;

export const SettingButton = styled.div`
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #0e4042;
  padding: 8px 16px;
  color: white;

  &:hover {
    filter: brightness(120%);
  }
`;

const CreditWrapper = styled.div`
  display: inline;
`;

export default function Settings() {
  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Game Settings</SectionTitle>
        <SectionContentScroll>
          <SettingsWrapper>
            <SettingsAuth />
            <SettingsMusic />
          </SettingsWrapper>
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Credits</SectionTitle>
        <SectionContentScroll>
          <CreditWrapper>
            The title screen background is a modified version of this{" "}
            <Link
              href="https://codepen.io/yuanchuan/pen/wZJqNK"
              target="_blank"
            >
              Codepen
            </Link>
            .
          </CreditWrapper>
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
