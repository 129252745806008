import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { retreatFight, startFight } from "redux/actions";
import { getCharacter, getFight } from "redux/selectors";
import { getItemData, getMobOverlayDialog } from "utils/stats";
import FightStats from "components/base/FightStats";
import {
  FightOverlay,
  FightOverlayTitle,
  OverlayList,
  OverlaySubtitle,
  ListItem,
  ItemName,
  ItemInfo,
  ItemWrapper,
  ItemImageWrapper,
  OverlayActions,
  OverlayButton,
} from "components/ui/FightOverlay";
import { BattleRatings } from "types";
import BattleRating from "components/base/BattleRating";
import BattleRatingSmall from "components/base/BattleRatingSmall";
import ItemIcon from "components/base/ItemIcon";
import useDialog from "components/hooks/useDialog";
import Dialogs from "components/base/Dialogs";

const DialogWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
`;

const FightStatsWrapper = styled.div`
  margin-bottom: 25px;
`;

const PreviousRatingWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RatingWrapper = styled.div`
  display: inline-block;
`;

export default function FightPreview() {
  const {
    data: { battleRatings },
  } = useSelector(getCharacter);
  const { opponent, status } = useSelector(getFight);
  const dispatch = useDispatch();

  const activeDialog = getMobOverlayDialog(opponent.dialogs, status);

  const { currentStep, currentDialog, hasAction } = useDialog(
    activeDialog || [],
    true
  );

  return (
    <FightOverlay>
      <FightOverlayTitle>Starship Analysis: {opponent.name}</FightOverlayTitle>
      {!!opponent.isTutorial && (
        <DialogWrapper>
          <Dialogs
            avatar={opponent.images.pilot}
            currentStep={currentStep}
            currentDialog={currentDialog}
            hasAction={hasAction}
          />
        </DialogWrapper>
      )}
      <FightStatsWrapper>
        <OverlaySubtitle>Core Attributes</OverlaySubtitle>
        <FightStats />
      </FightStatsWrapper>
      <OverlayList>
        <OverlaySubtitle>Battle Rewards</OverlaySubtitle>
        {Object.entries(opponent.drops).map(([key, drop]) => {
          const ratingKey = key as BattleRatings;
          return (
            <ListItem key={drop.slug}>
              <ItemWrapper>
                <ItemImageWrapper>
                  <ItemIcon slug={drop.slug} />
                </ItemImageWrapper>
                <ItemName>{getItemData(drop.slug).name}</ItemName>
              </ItemWrapper>
              <ItemInfo>
                <BattleRatingSmall rating={ratingKey} />
              </ItemInfo>
            </ListItem>
          );
        })}
      </OverlayList>
      {!!battleRatings[opponent.slug] && (
        <PreviousRatingWrapper>
          <OverlaySubtitle>Best Previous Battle Rating</OverlaySubtitle>
          <RatingWrapper>
            <BattleRating rating={battleRatings[opponent.slug]} />
          </RatingWrapper>
        </PreviousRatingWrapper>
      )}
      <OverlayActions>
        <OverlayButton onClick={() => dispatch(retreatFight())}>
          Retreat
        </OverlayButton>
        <OverlayButton isHighlight onClick={() => dispatch(startFight())}>
          Battle
        </OverlayButton>
      </OverlayActions>
    </FightOverlay>
  );
}
