import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import creditsImage from "assets/credits.svg";
import enhancementsImage from "assets/enhancements.svg";
import LargeWeakenBar from "components/base/LargeWeakenBar";
import Medallions from "components/base/Medallions";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import { BASE_STATS_INFO } from "data/baseStats";
import { buyEnhanceStat, buyResetStats, showMessage } from "redux/actions";
import { getCharacter } from "redux/selectors";
import { CharacterBaseStats } from "types";
import { COLORS } from "utils/constants";
import {
  getShipData,
  getStatsResetCost,
  getStatsTokensSpent,
  MAX_BASE_STAT_VALUE,
} from "utils/stats";
import { ScreenButton, ScreenButtonWrapper } from "./DataScreen";

interface BaseStatsProps {
  canEnhance?: boolean;
  onSetHighlightStat: (x: keyof CharacterBaseStats) => void;
  onResetHighlightStat: () => void;
}

interface IconProps {
  image: string;
}

interface BaseStatProps {
  enhanceable: boolean;
}

const BaseStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BaseStatsList = styled.div``;

const EnhancementsIcon = styled.div<IconProps>`
  width: 16px;
  height: 16px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.ENHANCEMENTS};
`;

const EnhancementCostWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.5;
  transition: all 100ms ease-out;
  color: ${COLORS.ENHANCEMENTS};
`;

const EnhancementCost = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const EnhanceText = styled.div`
  display: none;
`;
const CostText = styled.div``;

const enhanceableMixin = css`
  :hover ${EnhancementCostWrapper} {
    opacity: 1;
  }

  :hover ${EnhanceText} {
    display: block;
  }

  :hover ${CostText} {
    display: none;
  }
`;

const BaseStat = styled.div<BaseStatProps>`
  padding-bottom: 20px;
  cursor: pointer;

  ${(props) => !!props.enhanceable && enhanceableMixin}
`;

const BaseStatIcon = styled.div<IconProps>`
  height: 20px;
  width: 20px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const StatInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatName = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const BarWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Credits = styled.div`
  display: flex;
  gap: 2px;
  color: ${COLORS.CREDITS};
  align-items: center;
`;

const CreditsIcon = styled.div<IconProps>`
  height: 18px;
  width: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

export default function BaseStats({
  canEnhance = false,
  onSetHighlightStat,
  onResetHighlightStat,
}: BaseStatsProps) {
  const dispatch = useDispatch();
  const {
    data: { currentBaseStats, enhancedBaseStats, weakenedBaseStats, ship },
  } = useSelector(getCharacter);

  const statsCosts = getShipData(ship).baseStatsCosts;

  const tokensSpent = getStatsTokensSpent(enhancedBaseStats, ship);
  // Use spent tokens instead of reset cost to enable/disable reset button, since mechanical engineers reset for free
  const canReset = !!tokensSpent;
  const resetCost = getStatsResetCost(enhancedBaseStats, ship);

  const enhanceStat = (stat: keyof CharacterBaseStats) => {
    if (!!canEnhance) {
      dispatch(buyEnhanceStat({ baseStat: stat }));
    } else {
      dispatch(
        showMessage(`Find the nearest Shipworks to enhance this attribute`)
      );
    }
  };

  return (
    <BaseStatsWrapper>
      <BaseStatsList>
        <Medallions />
        {BASE_STATS_INFO.map((statInfo) => {
          const statSlug = statInfo.slug as keyof CharacterBaseStats;
          return (
            <BaseStat
              key={statSlug}
              enhanceable={canEnhance}
              onClick={() => enhanceStat(statSlug)}
              onMouseEnter={() => onSetHighlightStat(statSlug)}
              onMouseLeave={onResetHighlightStat}
            >
              <Trigger>
                <StatInfo>
                  <StatName>{statInfo.name}</StatName>
                  <EnhancementCostWrapper>
                    <EnhanceText>Enhance Stat:</EnhanceText>
                    <CostText>Cost:</CostText>
                    <EnhancementCost>
                      <EnhancementsIcon image={enhancementsImage} />
                      {statsCosts[statSlug]}
                    </EnhancementCost>
                  </EnhancementCostWrapper>
                </StatInfo>
                <BarWrapper>
                  <BaseStatIcon image={statInfo.image} color={statInfo.color} />
                  <LargeWeakenBar
                    number={currentBaseStats[statSlug]}
                    weakenNumber={weakenedBaseStats[statSlug]}
                    maxNumber={MAX_BASE_STAT_VALUE}
                    color={statInfo.color}
                    isReversed={false}
                  />
                </BarWrapper>
                <Tooltip>
                  <TooltipName>{statInfo.name}</TooltipName>
                  <TooltipDesc>{statInfo.description}</TooltipDesc>
                </Tooltip>
              </Trigger>
            </BaseStat>
          );
        })}
      </BaseStatsList>
      {!!canEnhance && (
        <ScreenButtonWrapper>
          <Trigger>
            <ScreenButton
              onClick={() => dispatch(buyResetStats({ credits: resetCost }))}
              $isDisabled={!canReset}
            >
              Reset Medallions
              <Credits>
                <CreditsIcon image={creditsImage} />
                {resetCost}
              </Credits>
            </ScreenButton>
            <Tooltip isAbove>
              <TooltipName>Reset Medallions</TooltipName>
              <TooltipDesc>
                Reset your core attributes back to their default values and get
                back your used medallions.
              </TooltipDesc>
            </Tooltip>
          </Trigger>
        </ScreenButtonWrapper>
      )}
    </BaseStatsWrapper>
  );
}
