import { CharacterBaseStats, CharacterDerivedStats, StatRanges } from "types";

import firepowerImage from "assets/stat-firepower.svg";
import resilienceImage from "assets/stat-resilience.svg";
import speedImage from "assets/stat-speed.svg";
import precisionImage from "assets/stat-precision.svg";
import energyImage from "assets/stat-energy.svg";
import { BASE_STATS } from "./baseStats";
import { COLORS } from "utils/constants";
import {
  roundWhole,
  roundTwoDecimals,
  formatNumber,
  formatMultiplier,
  formatPercentage,
  formatAddNumber,
  formatAddMultiplier,
  formatAddPercentage,
  formatBuff,
  formatRange,
  formatAddRange,
} from "utils/formatters";

export interface DerivedStatInfo {
  name: string;
  description: string;
  baseStat: keyof CharacterBaseStats;
  startingValue: number;
  incrementValue: number;
  image: string;
  color: string;
  rounder: (x: number) => number;
  formatter: (x: number) => string;
  addFormatter: (x: number) => string;
  buffFormatter: (x: number) => string;
}

export const DERIVED_STATS: Record<
  keyof CharacterDerivedStats,
  DerivedStatInfo
> = {
  maxHealth: {
    name: "Max Ship Health",
    description: "How much overall health your ship has.",
    baseStat: BASE_STATS.RESILIENCE,
    startingValue: 50,
    incrementValue: 20,
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
    buffFormatter: formatBuff,
  },
  attackDamage: {
    name: "Max Attack Damage",
    description: "The maximum ship damage you'll deal in regular attacks.",
    baseStat: BASE_STATS.FIREPOWER,
    startingValue: 10,
    incrementValue: 5,
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
    buffFormatter: formatBuff,
  },
  attackSpeed: {
    name: "Attack Speed",
    description:
      "This stat determines how frequently you can take actions (like attacking, performing skills, or using supplies) in battle.",
    baseStat: BASE_STATS.SPEED,
    startingValue: 1,
    incrementValue: 0.1,
    image: speedImage,
    color: COLORS.SPEED,
    rounder: roundTwoDecimals,
    formatter: formatMultiplier,
    addFormatter: formatAddMultiplier,
    buffFormatter: formatBuff,
  },
  attackAccuracy: {
    name: "Attack Precision",
    description:
      "This stat determines your minimum damage in regular attacks, calculating it as a percentage of your maximum damage.",
    baseStat: BASE_STATS.PRECISION,
    startingValue: 0,
    incrementValue: 0.02,
    image: precisionImage,
    color: COLORS.PRECISION,
    rounder: roundTwoDecimals,
    formatter: formatPercentage,
    addFormatter: formatAddPercentage,
    buffFormatter: formatBuff,
  },
  weakenParts: {
    name: "Max Weaken Components",
    description:
      "The max amount you can weaken an opponent's specific part with each attack, which degrades their stats during battle.",
    baseStat: BASE_STATS.FIREPOWER,
    startingValue: 0.5,
    incrementValue: 0.1,
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
    buffFormatter: formatBuff,
  },
  damageReduction: {
    name: "Damage Shields",
    description:
      "When you're hit by an opponent, your damage shields reduce the damage by this amount.",
    baseStat: BASE_STATS.RESILIENCE,
    startingValue: 0,
    incrementValue: 1,
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
    buffFormatter: formatBuff,
  },
  weakenPartsReduction: {
    name: "Component Shields",
    description:
      "When your ship parts are targeted by an opponent, your component shields reduce how much that part is weakened by this amount.",
    baseStat: BASE_STATS.RESILIENCE,
    startingValue: 0,
    incrementValue: 0.05,
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
    buffFormatter: formatBuff,
  },
  criticalHitChance: {
    name: "Critical Hit Chance",
    description: "Chance of landing a critical hit on your opponent.",
    baseStat: BASE_STATS.PRECISION,
    startingValue: 0,
    incrementValue: 0.01,
    image: precisionImage,
    color: COLORS.PRECISION,
    rounder: roundTwoDecimals,
    formatter: formatPercentage,
    addFormatter: formatAddPercentage,
    buffFormatter: formatBuff,
  },
  criticalHitMultiplier: {
    name: "Critical Hit Boost",
    description:
      "Increases the damage you deal when you land a critical hit, which is a multiple of your max attack damage.",
    baseStat: BASE_STATS.FIREPOWER,
    startingValue: 2,
    incrementValue: 0.1,
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    rounder: roundTwoDecimals,
    formatter: formatMultiplier,
    addFormatter: formatAddMultiplier,
    buffFormatter: formatBuff,
  },
  dodgeChance: {
    name: "Dodge Chance",
    description: "Increases the chances of outright dodging an attack.",
    baseStat: BASE_STATS.SPEED,
    startingValue: 0,
    incrementValue: 0.01,
    image: speedImage,
    color: COLORS.SPEED,
    rounder: roundTwoDecimals,
    formatter: formatPercentage,
    addFormatter: formatAddPercentage,
    buffFormatter: formatBuff,
  },
  movementSpeed: {
    name: "Movement Speed",
    description: "How quickly your ship can move around the planets.",
    baseStat: BASE_STATS.SPEED,
    startingValue: 1,
    incrementValue: 0.1,
    image: speedImage,
    color: COLORS.SPEED,
    rounder: roundTwoDecimals,
    formatter: formatMultiplier,
    addFormatter: formatAddMultiplier,
    buffFormatter: formatBuff,
  },
  fasterRecharge: {
    name: "Faster Ability Recharge",
    description:
      "Your active pilot skills recharge faster during battle, the number of turns to recharge reduced by this value.",
    baseStat: BASE_STATS.ENERGY,
    startingValue: 0,
    incrementValue: 0.1,
    image: energyImage,
    color: COLORS.ENERGY,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
    buffFormatter: formatBuff,
  },
  energyMultiplier: {
    name: "Antimatter Boost",
    description: "Increases the efficacy of your pilot skills.",
    baseStat: BASE_STATS.ENERGY,
    startingValue: 1,
    incrementValue: 0.1,
    image: energyImage,
    color: COLORS.ENERGY,
    rounder: roundTwoDecimals,
    formatter: formatMultiplier,
    addFormatter: formatAddMultiplier,
    buffFormatter: formatBuff,
  },
};

export interface StatRangeInfo {
  name: string;
  description: string;
  baseStats: (keyof CharacterBaseStats)[];
  images: string[];
  colors: string[];
  rounder: (x: number) => number;
  formatter: (x: number, y: number) => string;
  addFormatter: (x: number, y: number) => string;
}

export const STAT_RANGES: Record<keyof StatRanges, StatRangeInfo> = {
  attackDamageRange: {
    name: "Attack Damage Range",
    description:
      "The range of damage you'll deal in a regular attack. Your Firepower determines max damage. Your Precision determines min damage.",
    baseStats: [BASE_STATS.FIREPOWER, BASE_STATS.PRECISION],
    images: [firepowerImage, precisionImage],
    colors: [COLORS.FIREPOWER, COLORS.PRECISION],
    rounder: roundWhole,
    formatter: formatRange,
    addFormatter: formatAddRange,
  },
  weakenPartsRange: {
    name: "Weaken Components Range",
    description:
      "The range of weaken parts you'll deal in a regular attack. Your Firepower determines max weaken parts. Your Precision determines min weaken parts.",
    baseStats: [BASE_STATS.FIREPOWER, BASE_STATS.PRECISION],
    images: [firepowerImage, precisionImage],
    colors: [COLORS.FIREPOWER, COLORS.PRECISION],
    rounder: roundTwoDecimals,
    formatter: formatRange,
    addFormatter: formatAddRange,
  },
};

export type DerivedStatDisplayModel =
  | {
      isRange?: true;
      slug: keyof StatRanges;
    }
  | {
      isRange?: false;
      slug: keyof CharacterDerivedStats;
    };

export interface DerivedStatsGroupModel {
  name: string;
  stats: DerivedStatDisplayModel[];
}

export const DERIVED_STATS_GROUPS: Record<string, DerivedStatsGroupModel> = {
  attack: {
    name: "Attack",
    stats: [
      {
        slug: "attackDamageRange",
        isRange: true,
      },
      {
        slug: "weakenPartsRange",
        isRange: true,
      },
      { slug: "attackAccuracy" },
      { slug: "attackSpeed" },
      { slug: "criticalHitMultiplier" },
      { slug: "criticalHitChance" },
    ],
  },
  defense: {
    name: "Defense",
    stats: [
      { slug: "maxHealth" },
      { slug: "damageReduction" },
      { slug: "weakenPartsReduction" },
      { slug: "dodgeChance" },
    ],
  },
  abilities: {
    name: "Pilot Abilities",
    stats: [{ slug: "fasterRecharge" }, { slug: "energyMultiplier" }],
  },
  movement: {
    name: "Movement",
    stats: [{ slug: "movementSpeed" }],
  },
};
