import { GradeModel, UpgradeModel } from "types";
import baseWeaponsImage from "assets/starship-base-weapons.svg";
import baseWeaponsIcon from "assets/starship-base-weapons-icon.svg";
import baseShieldsImage from "assets/starship-base-shields.svg";
import baseShieldsIcon from "assets/starship-base-shields-icon.svg";
import baseThrustersImage from "assets/starship-base-thrusters.svg";
import baseThrustersIcon from "assets/starship-base-thrusters-icon.svg";
import baseTargetingSystemImage from "assets/starship-base-targeting-system.svg";
import baseTargetingSystemIcon from "assets/starship-base-targeting-system-icon.svg";
import baseReactorCoreImage from "assets/starship-base-reactor-core.svg";
import baseReactorCoreIcon from "assets/starship-base-reactor-core-icon.svg";
import bishopLaserOneImage from "assets/upgrade-bishop-laser-one.svg";
import bishopLaserOneIcon from "assets/upgrade-bishop-laser-one-icon.svg";
import bishopLaserTwoImage from "assets/upgrade-bishop-laser-two.svg";
import bishopLaserTwoIcon from "assets/upgrade-bishop-laser-two-icon.svg";
import bishopLaserThreeImage from "assets/upgrade-bishop-laser-three.svg";
import bishopLaserThreeIcon from "assets/upgrade-bishop-laser-three-icon.svg";
import bishopLaserFourImage from "assets/upgrade-bishop-laser-four.svg";
import bishopLaserFourIcon from "assets/upgrade-bishop-laser-four-icon.svg";
import bishopShieldOneImage from "assets/upgrade-bishop-shield-one.svg";
import bishopShieldOneIcon from "assets/upgrade-bishop-shield-one-icon.svg";
import bishopShieldTwoImage from "assets/upgrade-bishop-shield-two.svg";
import bishopShieldTwoIcon from "assets/upgrade-bishop-shield-two-icon.svg";
import bishopShieldThreeImage from "assets/upgrade-bishop-shield-three.svg";
import bishopShieldThreeIcon from "assets/upgrade-bishop-shield-three-icon.svg";
import bishopShieldFourImage from "assets/upgrade-bishop-shield-four.svg";
import bishopShieldFourIcon from "assets/upgrade-bishop-shield-four-icon.svg";
import bishopThrusterOneImage from "assets/upgrade-bishop-thruster-one.svg";
import bishopThrusterOneIcon from "assets/upgrade-bishop-thruster-one-icon.svg";
import bishopThrusterTwoImage from "assets/upgrade-bishop-thruster-two.svg";
import bishopThrusterTwoIcon from "assets/upgrade-bishop-thruster-two-icon.svg";
import bishopThrusterThreeImage from "assets/upgrade-bishop-thruster-three.svg";
import bishopThrusterThreeIcon from "assets/upgrade-bishop-thruster-three-icon.svg";
import bishopThrusterFourImage from "assets/upgrade-bishop-thruster-four.svg";
import bishopThrusterFourIcon from "assets/upgrade-bishop-thruster-four-icon.svg";
import bishopScopeOneImage from "assets/upgrade-bishop-scope-one.svg";
import bishopScopeOneIcon from "assets/upgrade-bishop-scope-one-icon.svg";
import bishopScopeTwoImage from "assets/upgrade-bishop-scope-two.svg";
import bishopScopeTwoIcon from "assets/upgrade-bishop-scope-two-icon.svg";
import bishopScopeThreeImage from "assets/upgrade-bishop-scope-three.svg";
import bishopScopeThreeIcon from "assets/upgrade-bishop-scope-three-icon.svg";
import bishopScopeFourImage from "assets/upgrade-bishop-scope-four.svg";
import bishopScopeFourIcon from "assets/upgrade-bishop-scope-four-icon.svg";
import bishopCoreOneImage from "assets/upgrade-bishop-core-one.svg";
import bishopCoreOneIcon from "assets/upgrade-bishop-core-one-icon.svg";
import bishopCoreTwoImage from "assets/upgrade-bishop-core-two.svg";
import bishopCoreTwoIcon from "assets/upgrade-bishop-core-two-icon.svg";
import bishopCoreThreeImage from "assets/upgrade-bishop-core-three.svg";
import bishopCoreThreeIcon from "assets/upgrade-bishop-core-three-icon.svg";
import bishopCoreFourImage from "assets/upgrade-bishop-core-four.svg";
import bishopCoreFourIcon from "assets/upgrade-bishop-core-four-icon.svg";
import waveCellImage from "assets/upgrade-wave-cell.svg";
import waveCellIcon from "assets/upgrade-wave-cell-icon.svg";
import pulseLaserImage from "assets/upgrade-pulse-laser.svg";
import pulseLaserIcon from "assets/upgrade-pulse-laser-icon.svg";
import firebrandImage from "assets/upgrade-firebrand.svg";
import firebrandIcon from "assets/upgrade-firebrand-icon.svg";
import starBreakerImage from "assets/upgrade-breaker.svg";
import starBreakerIcon from "assets/upgrade-breaker-icon.svg";
import plasmaBladeImage from "assets/upgrade-plasma-blade.svg";
import plasmaBladeIcon from "assets/upgrade-plasma-blade-icon.svg";
import targetingChipImage from "assets/upgrade-targeting-chip.svg";
import targetingChipIcon from "assets/upgrade-targeting-chip-icon.svg";
import stellarSightImage from "assets/upgrade-stellar-sight.svg";
import stellarSightIcon from "assets/upgrade-stellar-sight-icon.svg";
import veloNexusImage from "assets/upgrade-velo-nexus.svg";
import veloNexusIcon from "assets/upgrade-velo-nexus-icon.svg";
import guardianShieldImage from "assets/upgrade-guardian-shield.svg";
import guardianShieldIcon from "assets/upgrade-guardian-shield-icon.svg";
import lightSentinelImage from "assets/upgrade-light-sentinel.svg";
import lightSentinelIcon from "assets/upgrade-light-sentinel-icon.svg";
import flashRocketImage from "assets/upgrade-flash-rocket.svg";
import flashRocketIcon from "assets/upgrade-flash-rocket-icon.svg";
import hyperburnerImage from "assets/upgrade-hyperburner.svg";
import hyperburnerIcon from "assets/upgrade-hyperburner-icon.svg";
import {
  attackAnimation,
  reactorCoreAnimation,
  shieldsAnimation,
  targetingSystemAnimation,
  thrusterAnimation,
} from "components/base/styles";
import { BASE_STATS } from "./baseStats";

const DEFAULT_WEAPONS_SPEED = 300;
const DEFAULT_SHIELDS_SPEED = 1200;
const DEFAULT_THRUSTERS_SPEED = 400;
const DEFAULT_TARGETING_SPEED = 1200;
const DEFAULT_REACTOR_SPEED = 1200;

export const UPGRADES: Record<string, UpgradeModel> = {
  // DEFAULT UPGRADES
  default_weapons: {
    slug: "default_weapons",
    name: "Base Weapon",
    description: "Crappy weapon that came with your starship.",
    image: baseWeaponsImage,
    icon: baseWeaponsIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_shields: {
    slug: "default_shields",
    name: "Base Shield",
    description: "Crappy shield that came with your starship.",
    image: baseShieldsImage,
    icon: baseShieldsIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirement: {
      baseStat: BASE_STATS.RESILIENCE,
      value: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_thrusters: {
    slug: "default_thrusters",
    name: "Base Thrusters",
    description: "Crappy thrusters that came with your starship.",
    image: baseThrustersImage,
    icon: baseThrustersIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirement: {
      baseStat: BASE_STATS.SPEED,
      value: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_targeting_system: {
    slug: "default_targeting_system",
    name: "Base Targeting Grid",
    description: "Crappy targeting system that came with your starship.",
    image: baseTargetingSystemImage,
    icon: baseTargetingSystemIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirement: {
      baseStat: BASE_STATS.PRECISION,
      value: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_reactor_core: {
    slug: "default_reactor_core",
    name: "Base Reactor Core",
    description: "Crappy reactor core that came with your starship.",
    image: baseReactorCoreImage,
    icon: baseReactorCoreIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirement: {
      baseStat: BASE_STATS.ENERGY,
      value: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  // CITY SHOP UPGRADES
  bishop_laser_one: {
    slug: "bishop_laser_one",
    name: "Bishop Laser I",
    description: "A standard low performance laser made in Bishop City.",
    image: bishopLaserOneImage,
    icon: bishopLaserOneIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackDamage: 0.4,
      weakenParts: 0.3,
      criticalHitMultiplier: 0.3,
    },
  },
  bishop_laser_two: {
    slug: "bishop_laser_two",
    name: "Bishop Laser II",
    description: "A standard medium performance laser made in Bishop City.",
    image: bishopLaserTwoImage,
    icon: bishopLaserTwoIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackDamage: 0.4,
      weakenParts: 0.3,
      criticalHitMultiplier: 0.3,
    },
  },
  bishop_laser_three: {
    slug: "bishop_laser_three",
    name: "Bishop Laser III",
    description: "A standard high performance laser made in Bishop City.",
    image: bishopLaserThreeImage,
    icon: bishopLaserThreeIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackDamage: 0.4,
      weakenParts: 0.3,
      criticalHitMultiplier: 0.3,
    },
  },
  bishop_laser_four: {
    slug: "bishop_laser_four",
    name: "Bishop Laser IV",
    description: "A standard very high performance laser made in Bishop City.",
    image: bishopLaserFourImage,
    icon: bishopLaserFourIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackDamage: 0.4,
      weakenParts: 0.3,
      criticalHitMultiplier: 0.3,
    },
  },
  bishop_shield_one: {
    slug: "bishop_shield_one",
    name: "Bishop Shield I",
    description: "A standard low performance shield made in Bishop City.",
    image: bishopShieldOneImage,
    icon: bishopShieldOneIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirement: {
      baseStat: BASE_STATS.RESILIENCE,
      value: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 0.2,
      damageReduction: 0.5,
      weakenPartsReduction: 0.3,
    },
  },
  bishop_shield_two: {
    slug: "bishop_shield_two",
    name: "Bishop Shield II",
    description: "A standard medium performance shield made in Bishop City.",
    image: bishopShieldTwoImage,
    icon: bishopShieldTwoIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirement: {
      baseStat: BASE_STATS.RESILIENCE,
      value: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 0.4,
      damageReduction: 0.3,
      weakenPartsReduction: 0.3,
    },
  },
  bishop_shield_three: {
    slug: "bishop_shield_three",
    name: "Bishop Shield III",
    description: "A standard high performance shield made in Bishop City.",
    image: bishopShieldThreeImage,
    icon: bishopShieldThreeIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirement: {
      baseStat: BASE_STATS.RESILIENCE,
      value: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 0.4,
      damageReduction: 0.3,
      weakenPartsReduction: 0.3,
    },
  },
  bishop_shield_four: {
    slug: "bishop_shield_four",
    name: "Bishop Shield IV",
    description: "A standard very high performance shield made in Bishop City.",
    image: bishopShieldFourImage,
    icon: bishopShieldFourIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirement: {
      baseStat: BASE_STATS.RESILIENCE,
      value: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 0.4,
      damageReduction: 0.3,
      weakenPartsReduction: 0.3,
    },
  },
  bishop_thruster_one: {
    slug: "bishop_thruster_one",
    name: "Bishop Thruster I",
    description: "A standard low performance thruster made in Bishop City.",
    image: bishopThrusterOneImage,
    icon: bishopThrusterOneIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirement: {
      baseStat: BASE_STATS.SPEED,
      value: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 0.2,
      dodgeChance: 0.5,
      movementSpeed: 0.3,
    },
  },
  bishop_thruster_two: {
    slug: "bishop_thruster_two",
    name: "Bishop Thruster II",
    description: "A standard medium performance thruster made in Bishop City.",
    image: bishopThrusterTwoImage,
    icon: bishopThrusterTwoIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirement: {
      baseStat: BASE_STATS.SPEED,
      value: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 0.4,
      dodgeChance: 0.3,
      movementSpeed: 0.3,
    },
  },
  bishop_thruster_three: {
    slug: "bishop_thruster_three",
    name: "Bishop Thruster III",
    description: "A standard high performance thruster made in Bishop City.",
    image: bishopThrusterThreeImage,
    icon: bishopThrusterThreeIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirement: {
      baseStat: BASE_STATS.SPEED,
      value: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 0.4,
      dodgeChance: 0.3,
      movementSpeed: 0.3,
    },
  },
  bishop_thruster_four: {
    slug: "bishop_thruster_four",
    name: "Bishop Thruster IV",
    description:
      "A standard very high performance thruster made in Bishop City.",
    image: bishopThrusterFourImage,
    icon: bishopThrusterFourIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirement: {
      baseStat: BASE_STATS.SPEED,
      value: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 0.4,
      dodgeChance: 0.3,
      movementSpeed: 0.3,
    },
  },
  bishop_scope_one: {
    slug: "bishop_scope_one",
    name: "Bishop Scope I",
    description:
      "A standard low performance targeting system made in Bishop City.",
    image: bishopScopeOneImage,
    icon: bishopScopeOneIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirement: {
      baseStat: BASE_STATS.PRECISION,
      value: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackAccuracy: 0.5,
      criticalHitChance: 0.5,
    },
  },
  bishop_scope_two: {
    slug: "bishop_scope_two",
    name: "Bishop Scope II",
    description:
      "A standard medium performance targeting system made in Bishop City.",
    image: bishopScopeTwoImage,
    icon: bishopScopeTwoIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirement: {
      baseStat: BASE_STATS.PRECISION,
      value: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackAccuracy: 0.5,
      criticalHitChance: 0.5,
    },
  },
  bishop_scope_three: {
    slug: "bishop_scope_three",
    name: "Bishop Scope III",
    description:
      "A standard high performance targeting system made in Bishop City.",
    image: bishopScopeThreeImage,
    icon: bishopScopeThreeIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirement: {
      baseStat: BASE_STATS.PRECISION,
      value: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackAccuracy: 0.5,
      criticalHitChance: 0.5,
    },
  },
  bishop_scope_four: {
    slug: "bishop_scope_four",
    name: "Bishop Scope IV",
    description:
      "A standard very high performance targeting system made in Bishop City.",
    image: bishopScopeFourImage,
    icon: bishopScopeFourIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirement: {
      baseStat: BASE_STATS.PRECISION,
      value: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackAccuracy: 0.5,
      criticalHitChance: 0.5,
    },
  },
  bishop_core_one: {
    slug: "bishop_core_one",
    name: "Bishop Core I",
    description: "A standard low performance reactor core made in Bishop City.",
    image: bishopCoreOneImage,
    icon: bishopCoreOneIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirement: {
      baseStat: BASE_STATS.ENERGY,
      value: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  bishop_core_two: {
    slug: "bishop_core_two",
    name: "Bishop Core II",
    description:
      "A standard medium performance reactor core made in Bishop City.",
    image: bishopCoreTwoImage,
    icon: bishopCoreTwoIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirement: {
      baseStat: BASE_STATS.ENERGY,
      value: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  bishop_core_three: {
    slug: "bishop_core_three",
    name: "Bishop Core III",
    description:
      "A standard high performance reactor core made in Bishop City.",
    image: bishopCoreThreeImage,
    icon: bishopCoreThreeIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirement: {
      baseStat: BASE_STATS.ENERGY,
      value: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  bishop_core_four: {
    slug: "bishop_core_four",
    name: "Bishop Core IV",
    description:
      "A standard very high performance reactor core made in Bishop City.",
    image: bishopCoreFourImage,
    icon: bishopCoreFourIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirement: {
      baseStat: BASE_STATS.ENERGY,
      value: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  // WEAPONS UPGRADES
  pulse_laser: {
    slug: "pulse_laser",
    name: "Pulse Laser",
    description:
      "A tiny laser upgrade that barely counts as an upgrade. Pew pew.",
    image: pulseLaserImage,
    icon: pulseLaserIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 2,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackDamage: 1,
    },
  },
  firebrand: {
    slug: "firebrand",
    name: "Firebrand",
    description: "A strong laser that inflicts meaningful damage.",
    image: firebrandImage,
    icon: firebrandIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 6,
    },
    grade: "elite",
    derivedStatsModifiers: {
      attackDamage: 0.5,
      weakenParts: 0.5,
    },
  },
  star_breaker: {
    slug: "star_breaker",
    name: "Star Breaker",
    description:
      "A powerful laser designed to incapacitate ship parts, so watch out.",
    image: starBreakerImage,
    icon: starBreakerIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 10,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      weakenParts: 1,
    },
  },
  plasma_blade: {
    slug: "plasma_blade",
    name: "Plasma Blade",
    description: "Uh... Try not to get hit by this.",
    image: plasmaBladeImage,
    icon: plasmaBladeIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 12,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      attackDamage: 0.5,
      weakenParts: 0.25,
      criticalHitMultiplier: 0.25,
    },
  },
  // SHIELDS UPGRADES
  guardian_shield: {
    slug: "guardian_shield",
    name: "Guardian Shield",
    description: "A force shield that increases your ship health.",
    image: guardianShieldImage,
    icon: guardianShieldIcon,
    animation: shieldsAnimation,
    animationSpeed: 800,
    part: "shields",
    requirement: {
      baseStat: BASE_STATS.RESILIENCE,
      value: 6,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxHealth: 1,
    },
  },
  light_sentinel: {
    slug: "light_sentinel",
    name: "Light Sentinel",
    description: "A powerful force shield that effortlessly deflects damage.",
    image: lightSentinelImage,
    icon: lightSentinelIcon,
    animation: shieldsAnimation,
    animationSpeed: 600,
    part: "shields",
    requirement: {
      baseStat: BASE_STATS.RESILIENCE,
      value: 11,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      damageReduction: 1,
    },
  },
  // THRUSTERS UPGRADES
  flash_rocket: {
    slug: "flash_rocket",
    name: "Kinetica",
    description: "Thrusters that increase your ability to avoid attacks.",
    image: flashRocketImage,
    icon: flashRocketIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirement: {
      baseStat: BASE_STATS.SPEED,
      value: 5,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      dodgeChance: 1,
    },
  },
  hyperburner: {
    slug: "hyperburner",
    name: "Hyperburner",
    description: "Thrusters that increase the frequency of your attacks.",
    image: hyperburnerImage,
    icon: hyperburnerIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirement: {
      baseStat: BASE_STATS.SPEED,
      value: 10,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackSpeed: 1,
    },
  },
  // TARGETING SYSTEM UPGRADES
  targeting_chip: {
    slug: "targeting_chip",
    name: "Small Scope",
    description:
      "A small targeting system upgrade that makes your attacks hit a bit harder.",
    image: targetingChipImage,
    icon: targetingChipIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirement: {
      baseStat: BASE_STATS.PRECISION,
      value: 3,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackAccuracy: 1,
    },
  },
  stellar_sight: {
    slug: "stellar_sight",
    name: "Stellar Sight",
    description:
      "A robust targeting system upgrade that makes your attacks hit harder.",
    image: stellarSightImage,
    icon: stellarSightIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirement: {
      baseStat: BASE_STATS.PRECISION,
      value: 6,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackAccuracy: 0.5,
      criticalHitChance: 0.5,
    },
  },
  // REACTOR CORE UPGRADES
  wave_cell: {
    slug: "wave_cell",
    name: "Wave Cell",
    description:
      "An upgrade to your reactor core that strengthens your pilot abilities.",
    image: waveCellImage,
    icon: waveCellIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirement: {
      baseStat: BASE_STATS.ENERGY,
      value: 4,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      energyMultiplier: 1,
    },
  },
  velo_nexus: {
    slug: "velo_nexus",
    name: "Velo Nexus",
    description:
      "An upgrade to your reactor core that allows you to employ pilot abilities more often.",
    image: veloNexusImage,
    icon: veloNexusIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirement: {
      baseStat: BASE_STATS.ENERGY,
      value: 5,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      fasterRecharge: 1,
    },
  },
};

interface GradeData {
  [key: string]: GradeModel;
}

export const GRADES: GradeData = {
  basic: {
    slug: "basic",
    name: "Basic",
    color: "#777777",
    multiplier: 1,
  },
  advanced: {
    slug: "advanced",
    name: "Advanced",
    color: "#0c5ff2",
    multiplier: 1.1,
  },
  elite: {
    slug: "elite",
    name: "Elite",
    color: "#dc2eef",
    multiplier: 1.25,
  },
  ultra: {
    slug: "ultra",
    name: "Ultra",
    color: "#facd05",
    multiplier: 1.5,
  },
};
