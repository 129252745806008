export const COLORS = {
  HEALTH: "#47b247",
  FIREPOWER: "#f23d3d",
  RESILIENCE: "#1fa000",
  SPEED: "#d766dd",
  PRECISION: "#e0d512",
  ENERGY: "#6acee5",
  STATS: "#95e8c0",
  BUFFS: "#95e8c0",
  DEBUFFS: "#e59595",
  CREDITS: "#b899c6",
  EXPERIENCE: "#99c7c9",
  ENHANCEMENTS: "#d3c47d",
  UNLOCKED: "#43fffa",
  LEVELS: "#fff648",
  LOCATION: "#ace2cb",
  ONLINE_PLAYERS: "#39b77b",
  BASIC: "#999999",
};

export const FIGHT_DIALOG_STATUSES = ["openingDialog", "winDialog"];
