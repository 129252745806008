import { createAction } from "@reduxjs/toolkit";
import { characterActions, gameActions, fightActions } from "./reducers";
import {
  BuildingPayload,
  BuyPayload,
  EnhancePayload,
  GetItemPayload,
  LogMessage,
  LoseItemPayload,
  NewCharPayload,
  OnlineUser,
  PartTypes,
  TrainPayload,
} from "types";
import { User } from "firebase/auth";

// Character actions
export const {
  setUserId,
  setUserName,
  setShipClass,
  setPilotProfession,
  setInitialCharacterState,
  clearCharacterState,
  moveLeft,
  moveRight,
  turnShip,
  stopMove,
  disableMovement,
  enableMovement,
  moveToPosition,
  showInsideShip,
  hideInsideShip,
  switchDataScreen,
  showPlayerDetails,
  showInsideBuilding,
  hideInsideBuilding,
  switchBuildingScreen,
  setNearBoundary,
  enterArea,
  animateAction,
  endAnimation,
  resetAnimations,
  takeDamage,
  resetDamage,
  gainHealth,
  setHealth,
  weakenPartFully,
  restoreAllParts,
  restorePart,
  setMobBattleRating,
  increaseExp,
  decreaseExp,
  levelUp,
  gainStatPoints,
  loseStatPoints,
  gainCredits,
  loseCredits,
  getSupply,
  removeSupply,
  getUpgrade,
  removeUpgrade,
  enhanceStat,
  resetStats,
  trainSkill,
  resetSkills,
  setInstalledUpgrade,
  setUninstalledUpgrade,
  setSkillRecharge,
  rechargeSkillsTurn,
  resetSkillsRecharge,
  setSkillValue,
  resetSkillsValues,
  addBuffs,
  resetBuffs,
  setMusicEnabled,
  setMusicDisabled,
} = characterActions;

// Character saga actions (no reducer)
export const validateCharacterName = createAction<string>(
  "character/validateCharacterName"
);
export const createCharacter = createAction<NewCharPayload>(
  "character/createCharacter"
);
export const loadUserData = createAction<User | null>("character/loadUserData");
export const continueAsCharacter = createAction(
  "character/continueAsCharacter"
);
export const move = createAction<string>("character/move");
export const enterBuilding = createAction<BuildingPayload>(
  "character/enterBuilding"
);
export const exitBuilding = createAction("character/exitBuilding");
export const buyRepair = createAction<BuyPayload>("character/buyRepair");
export const buyRestore = createAction<BuyPayload>("character/buyRestore");
export const buyEnhanceStat = createAction<EnhancePayload>(
  "character/buyEnhanceStat"
);
export const buyResetStats = createAction<BuyPayload>(
  "character/buyResetStats"
);
export const buyTrainSkill = createAction<TrainPayload>(
  "character/buyTrainSkill"
);
export const buyResetSkills = createAction("character/buyResetSkills");
export const buyShopItem = createAction<GetItemPayload>(
  "character/buyShopItem"
);
export const sellShopItem = createAction<LoseItemPayload>(
  "character/sellShopItem"
);
export const employSupply = createAction<string>("character/employSupply");
export const employFightSupply = createAction<string>(
  "character/employFightSupply"
);
export const installUpgrade = createAction<string>("character/installUpgrade");
export const uninstallUpgrade = createAction<PartTypes>(
  "character/uninstallUpgrade"
);
export const enableMusic = createAction("character/enableMusic");
export const disableMusic = createAction("character/disableMusic");

// Game actions
export const {
  setGameModeActive,
  setGameModeInactive,
  setNewUser,
  setMainMenuScreen,
  setNewCharacterScreen,
  setUserAccountLinked,
  setUserAccountUnlinked,
  showNameSelectMessage,
  showMessage,
  setOnlineUsers,
  hideWorld,
  showWorld,
  setLogMessages,
  startMusicPlayer,
  stopMusicPlayer,
  setCurrentTrack,
  setTrackChange,
  setCurrentPlaylist,
} = gameActions;

// Game saga actions (no reducer)
export const signInUserAccount = createAction("game/signInUserAccount");
export const linkUserAccount = createAction("game/linkUserAccount");
export const signOutUserAccount = createAction("game/signOutUserAccount");
export const unlinkUserAccount = createAction("game/unlinkUserAccount");
export const exitToMainMenu = createAction("game/exitToMainMenu");
export const getOnlineUsers = createAction<OnlineUser[]>("game/getOnlineUsers");
export const enterShip = createAction("game/enterShip");
export const exitShip = createAction("game/exitShip");
export const viewPlayerShip = createAction<string | null>(
  "game/viewPlayerShip"
);
export const addLogMessage = createAction<string>("game/addLogMessage");
export const getLogMessages = createAction<LogMessage[]>("game/getLogMessages");
export const playMusic = createAction("game/playMusic");
export const pauseMusic = createAction("game/pauseMusic");
export const playNextMusicTrack = createAction("game/playNextMusicTrack");
export const changePlanetPlaylist = createAction("game/changePlanetPlaylist");

// Fight actions
export const {
  setInitialFightState,
  clearFightState,
  setFightStatus,
  setupOpponent,
  addCharacterTurnPriority,
  addOpponentTurnPriority,
  resetTurnPriorities,
  enableFightActions,
  disableFightActions,
  attackOpponent,
  setOpponentHealth,
  addOpponentBuffs,
  resetOpponentBuffs,
  resetOpponentDamage,
  resetOpponentParts,
  animateOpponentAction,
  endOpponentAnimation,
  resetOpponentAnimations,
  setFightResults,
  resetFightResults,
} = fightActions;

// Fight saga actions (no reducer)
export const startMobDialog = createAction<string>("fight/startMobDialog");
export const previewFight = createAction("fight/previewFight");
export const retreatFight = createAction("fight/retreatFight");
export const startFight = createAction("fight/startFight");
export const continueFight = createAction("fight/continueFight");
export const fightAgain = createAction("fight/fightAgain");
export const targetWeapons = createAction("fight/targetWeapons");
export const targetShields = createAction("fight/targetShields");
export const targetThrusters = createAction("fight/targetThrusters");
export const targetTargetingComputer = createAction(
  "fight/targetTargetingComputer"
);
export const targetReactor = createAction("fight/targetReactor");
export const escapeFight = createAction("fight/escapeFight");
export const onwardFightWin = createAction("fight/onwardFightWin");
export const endFightWin = createAction("fight/endFightWin");
export const onwardFightLose = createAction("fight/onwardFightLose");
export const endFightLose = createAction("fight/endFightLose");

// Skills
export const activateSkill = createAction<string>("skill/activateSkill");
export const buffDerivedStats = createAction("skill/buffDerivedStats");
export const tripleShot = createAction("skill/tripleShot");
