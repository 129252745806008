import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  get,
  set,
  query,
  push,
  child,
  onValue,
  onDisconnect,
  serverTimestamp,
  limitToLast,
  orderByChild,
  equalTo,
} from "firebase/database";
import {
  getAuth,
  signInAnonymously,
  signInWithPopup,
  GoogleAuthProvider,
  EmailAuthProvider,
  linkWithPopup,
  onAuthStateChanged,
  signOut,
  User,
  unlink,
} from "firebase/auth";
import { CharacterData, FightState, LogMessage, OnlineUser } from "types";

const googleProvider = new GoogleAuthProvider();
const emailProvider = new EmailAuthProvider();

const firebaseConfig = {
  apiKey: "AIzaSyBcHkcgvJylBef3tjzNA0Qza6P-GN8Er-E",
  authDomain: "lightvoyagers.com",
  databaseURL: "https://light-voyager-default-rtdb.firebaseio.com",
  projectId: "light-voyager",
  storageBucket: "light-voyager.appspot.com",
  messagingSenderId: "836241672446",
  appId: "1:836241672446:web:1ac784020ad7b2c701bdaf",
  measurementId: "G-0WLQWYLH08",
};

initializeApp(firebaseConfig);

export const DB_READ_THROTTLE_THRESHOLD = 2 * 60 * 1000; // 2 minutes
export const DB_WRITE_THROTTLE_THRESHOLD = 500;

export const throttle = (callback: (...args: any[]) => void, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const setOnlineStatus = async (userId: string) => {
  const db = getDatabase();

  const connectedRef = ref(db, ".info/connected");

  onValue(connectedRef, (snapshot) => {
    if (snapshot.val() === true) {
      const userRef = ref(db, `users/${userId}`);
      const onlineStatusRef = child(userRef, "isOnline");
      set(onlineStatusRef, true);

      // Set to false on disconnect
      onDisconnect(onlineStatusRef).set(false);
    }
  });
};

export const getUserAuth = (
  existingUserCallback: (user: User | null) => any
) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (userAuth) => {
    existingUserCallback(userAuth);
  });
};

export const signInAnonymousUser = async () => {
  const auth = getAuth();

  const credential = await signInAnonymously(auth);
  return credential.user;
};

export const signInGoogleUser = async () => {
  const auth = getAuth();

  const credential = await signInWithPopup(auth, googleProvider);
  return credential.user;
};

export const linkGoogleUserAccount = async () => {
  const auth = getAuth();

  if (auth.currentUser) {
    const credential = await linkWithPopup(auth.currentUser, googleProvider);
    return credential.user;
  }
};

export const signOutUser = async () => {
  const auth = getAuth();

  const result = await signOut(auth);
  return result;
};

export const unlinkGoogleUserAccount = async () => {
  const auth = getAuth();

  if (auth.currentUser) {
    const result = await unlink(auth.currentUser, googleProvider.providerId);
    return result;
  }
};

export const unlinkEmailUserAccount = async () => {
  const auth = getAuth();

  if (auth.currentUser) {
    const result = await unlink(auth.currentUser, emailProvider.providerId);
    return result;
  }
};

export const setUserOffline = async (userId: string) => {
  const db = getDatabase();

  const userRef = ref(db, `users/${userId}`);
  const onlineStatusRef = child(userRef, "isOnline");
  return await set(onlineStatusRef, false);
};

export const getExistingUserName = async (userName: string) => {
  const db = getDatabase();

  const userNamesRef = ref(db, `userNames`);
  const snapshot = await get(child(userNamesRef, userName.toLowerCase()));
  return snapshot.exists();
};

export const claimUserName = async (userId: string, userName: string) => {
  const db = getDatabase();

  const userNamesRef = ref(db, "userNames");
  return await set(child(userNamesRef, userName.toLowerCase()), userId);
};

export const createNewCharacter = async (userId: string, userName: string) => {
  const db = getDatabase();

  const userRef = ref(db, `users/${userId}`);
  return await set(child(userRef, "userName"), userName);
};

export const saveCharacterData = (
  userId: string | null,
  characterData: CharacterData,
  fightData: FightState
) => {
  if (userId) {
    const db = getDatabase();

    const userRef = ref(db, `users/${userId}`);
    set(child(userRef, "characterData"), characterData);
    set(child(userRef, "fightData"), fightData);
  }
};

export const loadUser = async (userId: string) => {
  const db = getDatabase();

  const userRef = ref(db, `users/${userId}`);
  const snapshot = await get(userRef);
  const user = snapshot.val();
  return user;
};

export const loadOnlineUsers = async () => {
  const db = getDatabase();
  const usersRef = ref(db, `users/`);
  const onlineUsersQuery = query(
    usersRef,
    orderByChild("isOnline"),
    equalTo(true)
  );

  const snapshot = await get(onlineUsersQuery);
  const onlineUsers = snapshot.val();

  return onlineUsers;
};

export const getOnlineUsersData = async (
  usersCallback: (users: OnlineUser[]) => any
) => {
  const loadUsersData = async () => {
    try {
      const users = await loadOnlineUsers();
      if (users) {
        usersCallback(users);
      }
    } catch (error: any) {
      console.error("Error in loadUsersData", error.message);
    }
  };

  setInterval(loadUsersData, DB_READ_THROTTLE_THRESHOLD);
};

export const createLogMessage = async (
  userId: string,
  userName: string,
  text: string
) => {
  const db = getDatabase();

  const logMessage = {
    userId,
    userName,
    text,
    time: serverTimestamp(),
  };

  const messagesRef = ref(db, `logMessages/`);
  return await push(messagesRef, logMessage);
};

export const getAllLogMessages = async (
  chatCallback: (messages: LogMessage[]) => any
) => {
  const db = getDatabase();
  const messagesRef = ref(db, `logMessages/`);

  const messagesQuery = query(messagesRef, limitToLast(100));

  onValue(messagesQuery, (snapshot) => {
    const data = snapshot.val();
    chatCallback(data);
  });
};
