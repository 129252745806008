import { createSelector } from "@reduxjs/toolkit";
import { DERIVED_STATS, DerivedStatInfo } from "data/derivedStats";
import {
  RootState,
  CharacterBaseStats,
  CharacterDerivedStats,
  InstalledUpgradesData,
  CharacterData,
  DerivedStatBuffs,
  OpponentData,
  StatRanges,
} from "types";
import {
  calculateCurrentLevelExp,
  calculateNextLevelExp,
  calculateTotalBaseStats,
  calculateCurrentBaseStats,
  getUpgradeData,
  getUpgradesStats,
  getShipData,
  getPilotSkills,
  calculateMobBaseStats,
  getMobData,
  getFightTurns,
  getNextConsecutiveTurns,
  getMinAttackDamage,
  getMinWeakenParts,
  getStatRangeData,
  getBaseDerivedStats,
} from "utils/stats";

export const getCharacter = (state: RootState) => {
  const { character } = state;

  return {
    userId: character.userId,
    userName: character.userName,
    data: getFormattedCharacterData(character.data),
  };
};

export const getFormattedCharacterData = (characterData: CharacterData) => {
  const {
    ship,
    pilot,
    level,
    experience,
    enhancedBaseStats,
    trainedSkills,
    weakenedBaseStats,
    derivedStatBuffs,
    installedUpgrades,
  } = characterData;

  // Combine ship's initial stats with earned added base stats
  let totalBaseStats: CharacterBaseStats = enhancedBaseStats;
  if (ship) {
    const shipData = getShipData(ship);
    totalBaseStats = calculateTotalBaseStats(
      shipData.startingBaseStats,
      enhancedBaseStats
    );
  }

  // Get max value for bars
  const maxBaseStatValue = Math.max(...Object.values(totalBaseStats));

  // Get current base stats after weakened parts
  const currentBaseStats = calculateCurrentBaseStats(
    totalBaseStats,
    weakenedBaseStats
  );

  const baseDerivedStats = getBaseDerivedStats(currentBaseStats);

  const upgradesStats = getUpgradesStats(
    installedUpgrades,
    currentBaseStats,
    totalBaseStats
  );

  const completeDerivedStats = getCompleteDerivedStats(
    baseDerivedStats,
    upgradesStats,
    derivedStatBuffs
  );

  const baseDerivedStatRanges = getStatRanges(baseDerivedStats);
  const statRanges = getStatRanges(completeDerivedStats);
  const upgradesStatRanges = getUpgradesStatRanges(
    baseDerivedStatRanges,
    statRanges
  );

  return {
    ...characterData,
    currentLevelExp: calculateCurrentLevelExp(experience),
    nextLevelExp: calculateNextLevelExp(level),
    currentBaseStats,
    totalBaseStats,
    maxBaseStatValue,
    stats: completeDerivedStats,
    baseDerivedStats,
    upgradesStats,
    statRanges,
    baseDerivedStatRanges,
    upgradesStatRanges,
    shipUpgrades: getShipUpgrades(installedUpgrades),
    skills: getPilotSkills(
      pilot,
      trainedSkills,
      completeDerivedStats.fasterRecharge
    ),
  };
};

export const getFormattedOpponentData = (opponentData: OpponentData) => {
  const { slug, weakenedBaseStats, derivedStatBuffs } = opponentData;

  const {
    name,
    level,
    images,
    dialogs,
    baseStatsModifiers,
    baseStatsCosts,
    baseStatsTargets,
    installedUpgrades,
    drops,
    isTutorial,
  } = getMobData(slug);

  // Calculate Mob base stats on level and modifiers
  const totalBaseStats = calculateMobBaseStats(
    level,
    baseStatsModifiers,
    baseStatsCosts
  );

  // Get max value for bars
  const maxBaseStatValue = Math.max(...Object.values(totalBaseStats));

  // Get current base stats after weakened parts
  const currentBaseStats = calculateCurrentBaseStats(
    totalBaseStats,
    weakenedBaseStats
  );

  const baseDerivedStats = getBaseDerivedStats(currentBaseStats);

  const upgradesStats = getUpgradesStats(
    installedUpgrades,
    currentBaseStats,
    totalBaseStats
  );

  const completeDerivedStats = getCompleteDerivedStats(
    baseDerivedStats,
    upgradesStats,
    derivedStatBuffs
  );

  return {
    ...opponentData,
    name,
    images,
    dialogs,
    currentBaseStats,
    totalBaseStats,
    maxBaseStatValue,
    stats: completeDerivedStats,
    baseStatsTargets,
    drops,
    isTutorial,
  };
};

export const selectFight = (state: RootState) => {
  return state.fight;
};

export const getFight = createSelector(
  selectFight,
  getCharacter,
  (fight, character) => {
    const {
      opponent: opponentData,
      characterTurnPriority,
      opponentTurnPriority,
    } = fight;
    const opponent = getFormattedOpponentData(opponentData);

    const { attackSpeed: characterAttackSpeed } = character.data.stats;
    const { attackSpeed: opponentAttackSpeed } = opponent.stats;

    const { isCharacterCurrentTurn, isCharacterNextTurn } = getFightTurns(
      characterTurnPriority,
      opponentTurnPriority,
      characterAttackSpeed,
      opponentAttackSpeed
    );

    const nextConsecutiveTurns = getNextConsecutiveTurns(
      characterTurnPriority,
      opponentTurnPriority,
      opponentAttackSpeed
    );

    return {
      ...fight,
      opponent,
      isCharacterCurrentTurn,
      isCharacterNextTurn,
      nextConsecutiveTurns,
    };
  }
);

export const getGameState = (state: RootState) => {
  return state.game;
};

export const getOnlinePlayers = (state: RootState) => {
  const { game } = state;

  const onlinePlayersData = game.onlineUsers.map((user) => {
    return {
      userName: user.userName,
      data: getFormattedCharacterData(user.characterData),
    };
  });

  return onlinePlayersData;
};

// Calculate derived stats
const calculateCompleteDerivedStat = (
  derivedStat: DerivedStatInfo,
  baseDerivedStat: number,
  upgradeValue: number,
  buffValues: number[] = []
) => {
  return derivedStat.rounder(
    buffValues.reduce((acc, buff) => acc * buff, baseDerivedStat + upgradeValue)
  );
};

export const getCompleteDerivedStats = (
  baseDerivedStats: CharacterDerivedStats,
  upgrades: CharacterDerivedStats,
  buffs: DerivedStatBuffs
): CharacterDerivedStats => {
  const completeDerivedStats = Object.entries(DERIVED_STATS).reduce(
    (acc, [key, value]) => {
      const statType = key as keyof CharacterDerivedStats;
      const statInfo = value as DerivedStatInfo;
      return {
        ...acc,
        [statType]: calculateCompleteDerivedStat(
          statInfo,
          baseDerivedStats[statType],
          upgrades[statType],
          buffs[statType]
        ),
      };
    },
    {} as any
  );

  return completeDerivedStats;
};

export const getStatRanges = (
  derivedStats: CharacterDerivedStats
): StatRanges => {
  const { attackDamage, attackAccuracy, weakenParts } = derivedStats;
  const minAttackDamage = getMinAttackDamage(attackDamage, attackAccuracy);
  const minWeakenParts = getMinWeakenParts(weakenParts, attackAccuracy);

  const attackDamageRangeInfo = getStatRangeData("attackDamageRange");
  const weakenPartsRangeInfo = getStatRangeData("weakenPartsRange");

  return {
    attackDamageRange: {
      min: attackDamageRangeInfo.rounder(minAttackDamage),
      max: attackDamageRangeInfo.rounder(attackDamage),
    },
    weakenPartsRange: {
      min: weakenPartsRangeInfo.rounder(minWeakenParts),
      max: weakenPartsRangeInfo.rounder(weakenParts),
    },
  };
};

export const getUpgradesStatRanges = (
  baseRanges: StatRanges,
  statRanges: StatRanges
): StatRanges => {
  const attackDamageRangeInfo = getStatRangeData("attackDamageRange");
  const weakenPartsRangeInfo = getStatRangeData("weakenPartsRange");

  return {
    attackDamageRange: {
      min: attackDamageRangeInfo.rounder(
        statRanges.attackDamageRange.min - baseRanges.attackDamageRange.min
      ),
      max: attackDamageRangeInfo.rounder(
        statRanges.attackDamageRange.max - baseRanges.attackDamageRange.max
      ),
    },
    weakenPartsRange: {
      min: weakenPartsRangeInfo.rounder(
        statRanges.weakenPartsRange.min - baseRanges.weakenPartsRange.min
      ),
      max: weakenPartsRangeInfo.rounder(
        statRanges.weakenPartsRange.max - baseRanges.weakenPartsRange.max
      ),
    },
  };
};

export const getShipUpgrades = (installedUpgrades?: InstalledUpgradesData) => {
  installedUpgrades = installedUpgrades || {
    weapons: null,
    shields: null,
    thrusters: null,
    targetingSystem: null,
    reactorCore: null,
  };

  return {
    weapons: getUpgradeData(installedUpgrades.weapons || "default_weapons"),
    shields: getUpgradeData(installedUpgrades.shields || "default_shields"),
    thrusters: getUpgradeData(
      installedUpgrades.thrusters || "default_thrusters"
    ),
    targetingSystem: getUpgradeData(
      installedUpgrades.targetingSystem || "default_targeting_system"
    ),
    reactorCore: getUpgradeData(
      installedUpgrades.reactorCore || "default_reactor_core"
    ),
  };
};
