import styled from "styled-components";
import { CharacterDerivedStats } from "types";

import creditsImage from "assets/credits.svg";
import {
  getSupplyData,
  getPartData,
  getUpgradeData,
  isSupplyItem,
  getDerivedStatData,
} from "utils/stats";
import Tooltip, {
  CreditsIcon,
  StatIcon,
  TooltipBar,
  TooltipBarValue,
  TooltipCredits,
  TooltipDesc,
  TooltipGrade,
  TooltipInfo,
  TooltipName,
  TooltipRequirement,
  TooltipStat,
  TooltipType,
  TooltipValues,
  Trigger,
  ValuesIcon,
} from "components/base/Tooltip";
import { ATTRIBUTES } from "data/attributes";
import { BASE_STATS_INFO } from "data/baseStats";
import { useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";

interface ItemProps {
  slug: string;
  isTooltipAbove?: boolean;
}

interface UpgradeImageWrapperProps {
  color: string;
}

const UpgradeImageWrapper = styled.div<UpgradeImageWrapperProps>`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  background: linear-gradient(#000000, 60%, ${(props) => props.color}85);
  background-blend-mode: darken;
`;

const ItemImage = styled.img`
  width: 100%;
  display: block;
  border-radius: 4px;
  overflow: hidden;
`;

const ItemIcon = ({ slug, isTooltipAbove = false }: ItemProps) => {
  const {
    data: { totalBaseStats },
  } = useSelector(getCharacter);

  const isSupply = !!isSupplyItem(slug);

  const supply = isSupply ? getSupplyData(slug) : null;
  const upgrade = !isSupply ? getUpgradeData(slug) : null;

  if (!!supply) {
    const attributeInfo = ATTRIBUTES[supply.attribute];
    return (
      <Trigger>
        <ItemImage src={supply.icon} />
        <Tooltip isAbove={isTooltipAbove}>
          <TooltipName>{supply.name}</TooltipName>
          <TooltipType>Supply</TooltipType>
          <TooltipDesc>{supply.description}</TooltipDesc>
          <TooltipInfo>
            <TooltipStat>
              <StatIcon image={attributeInfo.image} />
              {attributeInfo.name}: {attributeInfo.formatter(supply.payload)}
            </TooltipStat>
            <TooltipCredits>
              <CreditsIcon image={creditsImage} />
              Credits: {supply.credits}
            </TooltipCredits>
          </TooltipInfo>
        </Tooltip>
      </Trigger>
    );
  }

  if (!!upgrade) {
    const { gradeData, requirement } = upgrade;
    const partData = getPartData(upgrade.part);
    const baseStatReq = BASE_STATS_INFO.find(
      (stat) => stat.slug === requirement.baseStat
    );

    return (
      <Trigger>
        <UpgradeImageWrapper color={upgrade.gradeData.color}>
          <ItemImage src={upgrade.icon} />
        </UpgradeImageWrapper>
        <Tooltip>
          <TooltipName color={gradeData.color}>{upgrade.name}</TooltipName>
          {!upgrade.isDefault && (
            <TooltipGrade color={gradeData.color}>
              {`${gradeData.name} ${partData.name}`}
            </TooltipGrade>
          )}
          {!!baseStatReq && requirement.value > 0 && (
            <TooltipRequirement color={baseStatReq.color}>
              {`${baseStatReq.name} required: ${requirement.value}`}
              <TooltipBar color={baseStatReq.color}>
                <TooltipBarValue
                  percentage={
                    (totalBaseStats[baseStatReq.slug] / requirement.value) * 100
                  }
                  color={baseStatReq.color}
                />
              </TooltipBar>
            </TooltipRequirement>
          )}
          <TooltipDesc>{upgrade.description}</TooltipDesc>
          <TooltipInfo>
            {Object.entries(upgrade.derivedStats).map(([key, value]) => {
              const statSlug = key as keyof CharacterDerivedStats;
              const statInfo = getDerivedStatData(statSlug);
              return (
                <TooltipValues key={statSlug} color={statInfo.color}>
                  <ValuesIcon image={statInfo.image} color={statInfo.color} />
                  {statInfo.name}: {statInfo.addFormatter(value)}
                </TooltipValues>
              );
            })}
            {!upgrade.isDefault && (
              <TooltipCredits>
                <CreditsIcon image={creditsImage} />
                {`Credits: ${upgrade.credits}`}
              </TooltipCredits>
            )}
          </TooltipInfo>
        </Tooltip>
      </Trigger>
    );
  }

  return <></>;
};

export default ItemIcon;
