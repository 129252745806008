import { call, put, select, takeLeading } from "redux-saga/effects";

import {
  CharacterBaseStats,
  GetItemPayload,
  InventoryItemModel,
  LoseItemPayload,
  PartTypes,
} from "types";

import {
  employSupply,
  getSupply,
  getUpgrade,
  installUpgrade,
  uninstallUpgrade,
  removeSupply,
  removeUpgrade,
  setInstalledUpgrade,
  setUninstalledUpgrade,
  showMessage,
  restorePart,
} from "redux/actions";
import { getCharacter, getFight } from "redux/selectors";
import {
  getInvalidUpgradeParts,
  getInventoryItemAmount,
  getSupplyData,
  getItemData,
  getPartsToRestore,
  getUpgradeData,
  isSupplyItem,
} from "utils/stats";
import { adjustHealthSaga, gainExpSaga, healSaga } from "./character";
import { BASE_STATS_INFO } from "data/baseStats";

export const ITEM_MAX_AMOUNT = 99;

export function* getItemsSaga({
  payload: itemsGained,
}: {
  payload: GetItemPayload[];
}) {
  const {
    data: { inventory },
  } = yield select(getCharacter);

  let exceededItemMaxAmount = false;

  for (const item of itemsGained) {
    // Enforce max amount of each item
    const itemAmount = getInventoryItemAmount(item.slug, inventory);
    if (itemAmount >= ITEM_MAX_AMOUNT) {
      const itemData = getItemData(item.slug);
      yield put(
        showMessage(
          `You've reached the inventory limit (${ITEM_MAX_AMOUNT}) for ${itemData.name}`
        )
      );
      exceededItemMaxAmount = true;
      continue;
    }

    const isSupply = isSupplyItem(item.slug);
    if (isSupply) {
      yield put(getSupply({ slug: item.slug, quantity: item.quantity }));
    } else {
      yield put(getUpgrade({ slug: item.slug, quantity: item.quantity }));
    }
  }

  if (exceededItemMaxAmount) {
    throw new Error();
  }
}

export function* loseItemsSaga({
  payload: itemsGained,
}: {
  payload: LoseItemPayload[];
}) {
  for (const item of itemsGained) {
    const isSupply = isSupplyItem(item.slug);
    if (isSupply) {
      yield put(removeSupply({ slug: item.slug, quantity: item.quantity }));
    } else {
      yield put(removeUpgrade({ slug: item.slug, quantity: item.quantity }));
    }
  }
}

export function* employSupplySaga({ payload }: { payload: string }) {
  const {
    data: { inventory },
  } = yield select(getCharacter);
  const supply = getSupplyData(payload);
  if (!supply) {
    return;
  }

  // Make sure the player actually has this item
  const inventorySupply = inventory.supplies.find(
    (item: InventoryItemModel) => item.slug === payload
  );
  if (!inventorySupply) {
    return;
  }

  try {
    // Use supply by calling its action and value
    // Only call() allows for handling errors, put() doesn't
    yield call(supply.action, supply.payload);

    // If used successfully, remove item
    yield put(removeSupply({ slug: payload, quantity: 1 }));
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

export function* employRepairKitSaga(healAmount: number) {
  const {
    data: { health, stats },
  } = yield select(getCharacter);

  // If health is already full, don't use up repair kit
  if (health >= stats.maxHealth) {
    throw new Error(`Your starship is already fully repaired, chill out`);
  }

  yield call(healSaga, healAmount);
}

export function* employRestoreKitSaga(restoreAmount: number) {
  const {
    data: { weakenedBaseStats },
  } = yield select(getCharacter);
  const { status } = yield select(getFight);

  if (status !== "fighting") {
    throw new Error(`You can only restore your parts while in battle`);
  }

  let restoreAmountRemaining = restoreAmount;

  const partsToRestore = getPartsToRestore(weakenedBaseStats);

  if (partsToRestore.length === 0) {
    throw new Error(
      `Your starship's parts are already fully restored, chill out`
    );
  }

  for (const [key, value] of partsToRestore) {
    const weakenedStat = key as keyof CharacterBaseStats;
    const weakenedAmount = value as number;

    if (restoreAmountRemaining <= 0) {
      break;
    }

    if (restoreAmountRemaining > weakenedAmount) {
      // Save remaining restore amount for next stat
      yield put(
        restorePart({
          baseStatRestored: weakenedStat,
          restorePartAmount: weakenedAmount,
        })
      );
      restoreAmountRemaining -= weakenedAmount;
    } else {
      // Use up rest of restore amount
      yield put(
        restorePart({
          baseStatRestored: weakenedStat,
          restorePartAmount: restoreAmountRemaining,
        })
      );
      restoreAmountRemaining = 0;
    }
  }
}

export function* employExpBoosterSaga(expAmount: number) {
  yield call(gainExpSaga, expAmount);
}

export function* installUpgradeSaga({ payload }: { payload: string }) {
  const {
    data: { inventory, installedUpgrades, totalBaseStats },
  } = yield select(getCharacter);
  const upgrade = getUpgradeData(payload);
  if (!upgrade) {
    return;
  }

  // Make sure the player actually has this upgrade
  const inventoryUpgrade = inventory.upgrades.find(
    (upgrade: InventoryItemModel) => upgrade.slug === payload
  );
  if (!inventoryUpgrade) {
    return;
  }

  try {
    const { requirement } = upgrade;
    const baseStatReq = BASE_STATS_INFO.find(
      (stat) => stat.slug === requirement.baseStat
    );
    const baseStatValue = totalBaseStats[requirement.baseStat];
    if (baseStatReq && baseStatValue < requirement.value) {
      throw new Error(
        `Requires ${baseStatReq.name} ${requirement.value} or higher to install.`
      );
    }

    const existingUpgrade = installedUpgrades[upgrade.part];
    if (existingUpgrade) {
      // Uninstall existing upgrade and put back in inventory
      yield put(getUpgrade({ slug: existingUpgrade, quantity: 1 }));
    }

    // Install new upgrade
    yield put(
      setInstalledUpgrade({
        part: upgrade.part,
        slug: payload,
      })
    );

    // If installed successfully, remove upgrade from inventory
    yield put(removeUpgrade({ slug: payload, quantity: 1 }));
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

export function* uninstallUpgradeSaga({
  payload: part,
}: {
  payload: PartTypes;
}) {
  const {
    data: { installedUpgrades },
  } = yield select(getCharacter);
  const existingUpgrade = installedUpgrades[part];
  if (!existingUpgrade) {
    return;
  }

  try {
    // Uninstall existing upgrade
    yield put(setUninstalledUpgrade(part));

    // Put uninstalled upgrade put back in inventory
    yield put(getUpgrade({ slug: existingUpgrade, quantity: 1 }));

    // Adjust health to new maxHealth, if maxHealth is lower
    yield call(adjustHealthSaga);
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

export function* adjustUpgradesSaga(): any {
  const {
    data: { installedUpgrades, totalBaseStats },
  } = yield select(getCharacter);

  const invalidParts = getInvalidUpgradeParts(
    installedUpgrades,
    totalBaseStats
  );

  for (const partKey of invalidParts) {
    const part = partKey as PartTypes;

    yield call(uninstallUpgradeSaga, { payload: part });
  }

  if (invalidParts.length > 0) {
    yield put(
      showMessage(
        `Certain upgrades have been uninstalled due to insufficient stats`
      )
    );
  }
}

export default function* itemSagas() {
  yield takeLeading(employSupply, employSupplySaga);
  yield takeLeading(installUpgrade, installUpgradeSaga);
  yield takeLeading(uninstallUpgrade, uninstallUpgradeSaga);
}
