export const roundWhole = (x: number): number => Math.round(x);
export const roundOneDecimal = (x: number): number => Math.round(x * 10) / 10;
export const roundTwoDecimals = (x: number): number =>
  Math.round(x * 100) / 100;

export const formatNumber = (x: number): string => `${x}`;
export const formatMultiplier = (x: number): string => `${x}x`;
export const formatPercentage = (x: number): string =>
  `${roundWhole(x * 100)}%`;
export const formatAddNumber = (x: number): string =>
  x >= 0 ? `+${x}` : `${x}`;
export const formatAddMultiplier = (x: number): string =>
  x >= 0 ? `+${x}x` : `${x}x`;
export const formatAddPercentage = (x: number): string =>
  x >= 0 ? `+${roundWhole(x * 100)}%` : `${roundWhole(x * 100)}%`;
export const formatBuff = (x: number) => `${x}X`;
export const formatRange = (min: number, max: number) => `${min} ↔ ${max}`;
export const formatAddRange = (min: number, max: number) =>
  `[+${min}] ↔ [+${max}]`;
