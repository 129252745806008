import { SupplyModel, SupplyTypeModel, SupplyTypes } from "types";
import minorRepairKitIcon from "assets/supply-minor-repair-kit.svg";
import mediumRepairKitIcon from "assets/supply-medium-repair-kit.svg";
import majorRepairKitIcon from "assets/supply-major-repair-kit.svg";
import minorRestoreModuleIcon from "assets/supply-minor-restore-module.svg";
import mediumRestoreModuleIcon from "assets/supply-medium-restore-module.svg";
import majorRestoreModuleIcon from "assets/supply-major-restore-module.svg";
import minorExpBoosterIcon from "assets/supply-minor-exp-booster.svg";
import mediumExpBoosterIcon from "assets/supply-medium-exp-booster.svg";
import majorExpBoosterIcon from "assets/supply-major-exp-booster.svg";
import {
  employRepairKitSaga,
  employExpBoosterSaga,
  employRestoreKitSaga,
} from "redux/sagas/item";

export const SUPPLIES: Record<string, SupplyModel> = {
  minor_repair_kit: {
    slug: "minor_repair_kit",
    name: "Minor Repair Kit",
    description: "A kit that repairs your ship's health slightly.",
    icon: minorRepairKitIcon,
    action: employRepairKitSaga,
    attribute: "health",
    payload: 25,
    credits: 10,
  },
  medium_repair_kit: {
    slug: "medium_repair_kit",
    name: "Medium Repair Kit",
    description: "A kit that repairs your ship's health medium...ly.",
    icon: mediumRepairKitIcon,
    action: employRepairKitSaga,
    attribute: "health",
    payload: 50,
    credits: 25,
  },
  major_repair_kit: {
    slug: "major_repair_kit",
    name: "Major Repair Kit",
    description: "A kit that heavily repairs your ship's health.",
    icon: majorRepairKitIcon,
    action: employRepairKitSaga,
    attribute: "health",
    payload: 200,
    credits: 100,
  },
  minor_restore_module: {
    slug: "minor_restore_module",
    name: "Minor Restore Parts Module",
    description:
      "A modules that restores your ship's parts slightly while in battle.",
    icon: minorRestoreModuleIcon,
    action: employRestoreKitSaga,
    attribute: "integrity",
    payload: 5,
    credits: 50,
  },
  medium_restore_module: {
    slug: "medium_restore_module",
    name: "Medium Restore Parts Module",
    description: "A modules that restores your ship's parts while in battle.",
    icon: mediumRestoreModuleIcon,
    action: employRestoreKitSaga,
    attribute: "integrity",
    payload: 10,
    credits: 100,
  },
  major_restore_module: {
    slug: "major_restore_module",
    name: "Major Restore Parts Module",
    description:
      "A modules that restores your ship's parts heavily while in battle.",
    icon: majorRestoreModuleIcon,
    action: employRestoreKitSaga,
    attribute: "integrity",
    payload: 25,
    credits: 250,
  },
  minor_exp_booster: {
    slug: "minor_exp_booster",
    name: "Minor Experience Booster",
    description: "A module that boosts your experience by a little bit.",
    icon: minorExpBoosterIcon,
    action: employExpBoosterSaga,
    attribute: "experience",
    payload: 100,
    credits: 200,
  },
  medium_exp_booster: {
    slug: "medium_exp_booster",
    name: "Medium Experience Booster",
    description: "A module that boosts your experience by a medium amount.",
    icon: mediumExpBoosterIcon,
    action: employExpBoosterSaga,
    attribute: "experience",
    payload: 250,
    credits: 500,
  },
  major_exp_booster: {
    slug: "major_exp_booster",
    name: "Major Experience Booster",
    description: "A module that boosts your experience by a large amount.",
    icon: majorExpBoosterIcon,
    action: employExpBoosterSaga,
    attribute: "experience",
    payload: 1000,
    credits: 2000,
  },
};

export const SUPPLY_TYPES: Record<SupplyTypes, SupplyTypeModel> = {
  health: {
    name: "Ship Health",
    inFight: true,
  },
  integrity: {
    name: "Component Integrity",
    inFight: true,
  },
  experience: {
    name: "Experience",
    inFight: false,
  },
};
