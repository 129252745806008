import { MusicTrackModel, PlaylistModel } from "types";

import lightVoyagersThemeMusic from "assets/light-voyager-theme.mp3";
import hyperlancerMusic from "assets/hyperlancer.mp3";
import neonGalaxyMusic from "assets/neon-galaxy.mp3";
import sectorMusic from "assets/sector.mp3";
import lockheedMusic from "assets/lockheed.mp3";
import hollowlandsMusic from "assets/hollowlands.mp3";
import grimlockMusic from "assets/grimlock.mp3";
import straylightMusic from "assets/straylight.mp3";

export interface PlaylistData {
  [key: string]: PlaylistModel;
}

export interface MusicData {
  [key: string]: MusicTrackModel;
}

export const PLAYLISTS: PlaylistData = {
  titleScreen: ["lightVoyagersTheme"],
  bishop: [
    "hyperlancer",
    "neonGalaxy",
    "sector",
    "lockheed",
    "hollowlands",
    "grimlock",
    "straylight",
  ],
};

export const MUSIC: MusicData = {
  lightVoyagersTheme: {
    name: "Light Voyagers Theme",
    audioFile: lightVoyagersThemeMusic,
  },
  hyperlancer: {
    name: "Hyperlancer",
    audioFile: hyperlancerMusic,
  },
  neonGalaxy: {
    name: "Neon Galaxy",
    audioFile: neonGalaxyMusic,
  },
  sector: {
    name: "Sector",
    audioFile: sectorMusic,
  },
  lockheed: {
    name: "Lockheed",
    audioFile: lockheedMusic,
  },
  hollowlands: {
    name: "Hollowlands",
    audioFile: hollowlandsMusic,
  },
  grimlock: {
    name: "Grimlock",
    audioFile: grimlockMusic,
  },
  straylight: {
    name: "Straylight",
    audioFile: straylightMusic,
  },
};
