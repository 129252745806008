import styled, { css } from "styled-components";

interface ScreenButtonProps {
  $isDisabled: boolean;
}

const disabledMixin = css`
  opacity: 0.3;
  pointer-events: none;
`;

export const DataScreen = styled.div`
  background-color: #0a1616;
  color: white;
  z-index: 2;
  opacity: 0.95;
  height: 100%;
`;

export const DataWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const Section = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.div`
  text-transform: uppercase;
  color: #43fffa;
  font-size: 24px;
  font-weight: 600;
  padding: 30px 30px 15px;
`;

export const SectionSubtitle = styled.div`
  color: #2da5b2;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const SectionContent = styled.div`
  flex-grow: 1;
  padding: 30px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
`;

export const SectionContentScroll = styled(SectionContent)`
  overflow-y: scroll;
`;

export const CategoryGroup = styled.div``;

export const CategoryName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  margin-bottom: 10px;
`;

export const CategorySeparator = styled.div`
  width: 100%;
  border-bottom: #2da5b2 0.5px solid;
  opacity: 0.75;
`;

export const ScreenButtonWrapper = styled.div`
  margin-top: 30px;
  align-self: end;
`;

export const ScreenButton = styled.div<ScreenButtonProps>`
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  background-color: #0e4042;
  padding: 8px 16px;
  color: white;
  display: flex;
  gap: 10px;
  align-items: center;

  &:hover {
    filter: brightness(120%);
  }

  ${(props) => props.$isDisabled && disabledMixin}
`;
