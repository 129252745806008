import { call, put, select, takeLeading } from "redux-saga/effects";

import {
  addLogMessage,
  changePlanetPlaylist,
  disableMovement,
  disableMusic,
  enableMovement,
  enableMusic,
  enterShip,
  exitShip,
  getLogMessages,
  getOnlineUsers,
  hideInsideShip,
  pauseMusic,
  playMusic,
  playNextMusicTrack,
  setLogMessages,
  setCurrentPlaylist,
  setCurrentTrack,
  setMusicDisabled,
  setMusicEnabled,
  setOnlineUsers,
  setTrackChange,
  showInsideShip,
  showPlayerDetails,
  startMusicPlayer,
  stopMusicPlayer,
  switchDataScreen,
  viewPlayerShip,
} from "redux/actions";
import { getCharacter, getFight, getGameState } from "redux/selectors";
import { LogMessage, OnlineUser } from "types";
import { FIGHT_DIALOG_STATUSES } from "utils/constants";
import {
  getNextMusicTrack,
  getPlanetData,
  getPlaylistData,
  isTrackInPlaylist,
  shuffleList,
} from "utils/stats";
import { createLogMessage } from "utils/storage/firebase";

export function* getOnlineUsersSaga({
  payload: users,
}: {
  payload: OnlineUser[];
}) {
  // Filter for only online users
  const onlineUsers = Object.values(users);
  yield put(setOnlineUsers(onlineUsers));
}

function* enterShipSaga() {
  // Prevent entering ship when in a fight
  const { status } = yield select(getFight);
  const isFighting =
    status !== "notFighting" && !FIGHT_DIALOG_STATUSES.includes(status);
  if (isFighting) {
    return;
  }

  yield put(showInsideShip());

  yield put(disableMovement());
}

function* exitShipSaga() {
  yield put(hideInsideShip());

  yield put(enableMovement());
}

function* viewPlayerShipSaga({ payload: player }: { payload: string | null }) {
  yield call(enterShipSaga);

  yield put(switchDataScreen("comms"));

  yield put(showPlayerDetails(player));
}

function* addLogMessageSaga({ payload: text }: { payload: string }) {
  // Get user ID and user name
  let { userId, userName } = yield select(getCharacter);

  // TODO: Any message validation

  // Add new message in Firebase
  yield createLogMessage(userId, userName, text);
}

function* getLogMessagesSaga({ payload: messages }: { payload: LogMessage[] }) {
  // Should already be limited by amount
  const logMessages = Object.values(messages);

  yield put(setLogMessages(logMessages));
}

function* playMusicSaga() {
  // Always check player setting before turning music on
  let {
    data: { settings },
  } = yield select(getCharacter);

  if (!!settings.isMusicEnabled) {
    yield put(startMusicPlayer());
  }
}

function* pauseMusicSaga() {
  yield put(stopMusicPlayer());
}

function* enableMusicSaga() {
  // Check if current track is in current playlist, if not switch to first song
  let { currentTrack, currentPlaylist } = yield select(getGameState);
  const isInPlaylist = isTrackInPlaylist(currentTrack, currentPlaylist);

  yield put(setMusicEnabled());

  if (!isInPlaylist) {
    yield call(playNextMusicTrackSaga);
  }

  yield call(playMusicSaga);
}

function* disableMusicSaga() {
  yield put(setMusicDisabled());
  yield call(pauseMusicSaga);
}

function* playNextMusicTrackSaga() {
  let { currentTrack, currentPlaylist } = yield select(getGameState);

  const nextTrack = getNextMusicTrack(currentTrack, currentPlaylist);

  yield put(setCurrentTrack(nextTrack));
  // Trigger play of new track, even if repeating same track
  yield put(setTrackChange());
}

export function* setTitleScreenPlaylistSaga() {
  const playlistData = getPlaylistData("titleScreen");

  const randomizedPlaylist = shuffleList(playlistData);

  yield put(setCurrentPlaylist(randomizedPlaylist));
}

function* changePlanetPlaylistSaga() {
  let {
    data: { location },
  } = yield select(getCharacter);
  const planetData = getPlanetData(location.planet);
  const playlistData = getPlaylistData(planetData.playlist);

  const randomizedPlaylist = shuffleList(playlistData);

  yield put(setCurrentPlaylist(randomizedPlaylist));
}

export default function* gameSagas() {
  yield takeLeading(getOnlineUsers, getOnlineUsersSaga);
  yield takeLeading(enterShip, enterShipSaga);
  yield takeLeading(exitShip, exitShipSaga);
  yield takeLeading(viewPlayerShip, viewPlayerShipSaga);
  yield takeLeading(addLogMessage, addLogMessageSaga);
  yield takeLeading(getLogMessages, getLogMessagesSaga);
  yield takeLeading(playMusic, playMusicSaga);
  yield takeLeading(pauseMusic, pauseMusicSaga);
  yield takeLeading(enableMusic, enableMusicSaga);
  yield takeLeading(disableMusic, disableMusicSaga);
  yield takeLeading(playNextMusicTrack, playNextMusicTrackSaga);
  yield takeLeading(changePlanetPlaylist, changePlanetPlaylistSaga);
}
