import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import creditsImage from "assets/credits.svg";
import shipworksEngineer from "assets/shipworks-engineer.svg";
import { DataScreen } from "components/base/DataScreen";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import EnhanceScreen from "components/ui/EnhanceScreen";
import ShipworksRepair from "components/ui/ShipworksRepair";
import UpgradeScreen from "components/ui/UpgradeScreen";
import { exitBuilding, switchBuildingScreen } from "redux/actions";
import { getCharacter } from "redux/selectors";
import { COLORS } from "utils/constants";
import ShipworksShopBuy from "./ShipworksShopBuy";
import ShipworksShopSell from "./ShipworksShopSell";

interface MenuItemProps {
  isActive: boolean;
}

interface IconProps {
  image: string;
}

const ShipworksInteriorWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const MenuWrapper = styled.div`
  background-color: #0e4042;
  color: white;
  z-index: 1;
  display: flex;
  opacity: 0.95;
`;

const BuildingName = styled.div`
  padding: 9px 30px;
  font-size: 28px;
  color: #43fffa;
  text-transform: uppercase;
  font-weight: 600;
  background: #000000;
`;

const activeMixin = css`
  background-color: #00000020;
  color: #43fffa;
`;

const MenuItem = styled.div<MenuItemProps>`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  padding: 15px 30px;
  box-sizing: border-box;
  ${(props) => props.isActive && activeMixin};

  &:hover {
    background-color: #00000020;
  }
`;

const CreditsWrapper = styled.div`
  padding: 15px 30px;
  margin-left: auto;
`;

const Credits = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${COLORS.CREDITS};
  display: flex;
  align-items: center;
  gap: 2px;
`;

const CreditsIcon = styled.div<IconProps>`
  height: 18px;
  width: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

const ExitButton = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box;
  color: #ffffff;
  padding: 15px 30px;
  background-color: #ffffff20;

  &:hover {
    background-color: #00000020;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  overflow-y: scroll;
`;

const DataScreenWrapper = styled.div`
  flex-basis: 70%;
`;

const EngineerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  align-items: stretch;
  align-self: end;
`;

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  opacity: 0.95;
`;

const Dialog = styled.div`
  font-size: 18px;
  background-color: #000000;
  align-self: stretch;
  padding: 30px;
`;

const EngineerName = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #0e4042;
  padding: 15px 30px;
`;

const EngineerImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ShipworksEngineer = styled.img`
  display: block;
  width: 90%;
`;

const SCREENS = [
  {
    slug: "repair",
    name: "Repair",
    component: <ShipworksRepair />,
    dialog:
      "I can't tell if your starship needs heavy repairs or just always looks like that. Anyway, we can replenish your ship's health or you can buy kits to repair your ship on the go.",
  },
  {
    slug: "enhance",
    name: "Enhance",
    component: <EnhanceScreen canEnhance={true} />,
    dialog:
      "Level up your starship's core attributes, which boosts certain calculated stats. Though is it worth using up valuable tokens on this, to put it delicately, piece of space crap?",
  },
  {
    slug: "install",
    name: "Install",
    component: <UpgradeScreen canInstall={true} />,
    dialog:
      "If you've collected upgrades in your inventory, we can install them onto your starship for you. God knows your ship could use it. Oh, did I say that last part out loud?",
  },
  {
    slug: "buy",
    name: "Buy",
    component: <ShipworksShopBuy />,
    dialog:
      "Buy amazing supplies and upgrades here, we have the best prices in the city. ...The only prices too, sure. ...Just give me your credits now.",
  },
  {
    slug: "sell",
    name: "Sell",
    component: <ShipworksShopSell />,
    dialog:
      "Sell your supplies and upgrades to us, if you even have anything of value. We'll give you a fair price for them. ...Well hmm, is fair the right word? ",
  },
];

export default function ShipworksInterior() {
  const dispatch = useDispatch();
  const {
    data: { credits, location },
  } = useSelector(getCharacter);

  const setScreen = SCREENS.find(
    (screen) => screen.slug === location.buildingScreen
  );
  const currentScreen = setScreen ? setScreen : SCREENS[0];

  // Initial dialog

  return (
    <ShipworksInteriorWrapper>
      <MenuWrapper>
        <BuildingName>Bishop Shipworks</BuildingName>

        {SCREENS.map((screen) => (
          <MenuItem
            key={screen.slug}
            isActive={currentScreen.slug === screen.slug}
            onClick={() => dispatch(switchBuildingScreen(screen.slug))}
          >
            {screen.name}
          </MenuItem>
        ))}

        <CreditsWrapper>
          <Trigger>
            <Credits>
              <CreditsIcon image={creditsImage} />
              {credits}
            </Credits>
            <Tooltip isLeft>
              <TooltipName>Credits Available</TooltipName>
              <TooltipDesc>
                How many credits you have to spend on repairs, stat resets,
                upgrades, and supplies.
              </TooltipDesc>
            </Tooltip>
          </Trigger>
        </CreditsWrapper>
        <ExitButton onClick={() => dispatch(exitBuilding())}>Exit</ExitButton>
      </MenuWrapper>
      <ContentWrapper>
        <DataScreenWrapper>
          <DataScreen>{currentScreen.component}</DataScreen>
        </DataScreenWrapper>
        <EngineerWrapper>
          <EngineerImageWrapper>
            <ShipworksEngineer src={shipworksEngineer} />
          </EngineerImageWrapper>
          <DialogWrapper>
            <EngineerName>Starship Engineer Hollis</EngineerName>
            <Dialog>
              {!!location.buildingDialog
                ? location.buildingDialog
                : currentScreen.dialog}
            </Dialog>
          </DialogWrapper>
        </EngineerWrapper>
      </ContentWrapper>
    </ShipworksInteriorWrapper>
  );
}
