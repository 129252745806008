import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import {
  employSupply,
  fightAgain,
  onwardFightLose,
  onwardFightWin,
} from "redux/actions";
import creditsImage from "assets/credits.svg";
import experienceImage from "assets/exp.svg";
import enhancementsImage from "assets/enhancements.svg";
import { getCharacter, getFight } from "redux/selectors";
import {
  getItemData,
  getMobOverlayDialog,
  getRepairSupplies,
} from "utils/stats";
import {
  FightOverlay,
  FightOverlayTitle,
  OverlayList,
  OverlaySubtitle,
  ListItem,
  ItemName,
  ItemInfo,
  ItemWrapper,
  ItemImageWrapper,
  OverlayActions,
  OverlayButton,
  DialogWrapper,
} from "components/ui/FightOverlay";
import BattleRating from "components/base/BattleRating";
import ItemIcon from "components/base/ItemIcon";
import { COLORS } from "utils/constants";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import useDialog from "components/hooks/useDialog";
import Dialogs from "components/base/Dialogs";

interface GainImageProps {
  image: string;
}

const OverlayHighlight = styled.div`
  text-transform: uppercase;
  color: #e3cb44;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
`;

const CurrentRatingWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RatingWrapper = styled.div`
  display: inline-block;
`;

const RewardsWrapper = styled.div`
  margin-bottom: 15px;
`;

const ExperienceImageWrapper = styled(ItemImageWrapper)`
  background-color: ${COLORS.EXPERIENCE}50;
`;

const CreditsImageWrapper = styled(ItemImageWrapper)`
  background-color: ${COLORS.CREDITS}50;
`;

const EnhancementsImageWrapper = styled(ItemImageWrapper)`
  background-color: ${COLORS.ENHANCEMENTS}50;
`;

const GainImage = styled.div<GainImageProps>`
  width: 100%;
  height: 100%;
  mask: url(${(props) => props.image}) no-repeat center;
  mask-size: 60%;
`;

const ExperienceImage = styled(GainImage)`
  background-color: ${COLORS.EXPERIENCE};
`;

const CreditsImage = styled(GainImage)`
  background-color: ${COLORS.CREDITS};
`;

const EnhancementsImage = styled(GainImage)`
  background-color: ${COLORS.ENHANCEMENTS};
`;

const QuickRepairWrapper = styled.div``;

const RepairSupplies = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ItemQuantity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000090;
  color: white;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  transition: opacity 200ms ease-out;
`;

const Item = styled.div`
  width: 44px;
  height: 44px;
  cursor: pointer;
  position: relative;

  &:hover ${ItemQuantity} {
    opacity: 1;
  }
`;

export default function FightResults() {
  const {
    data: { level, inventory },
  } = useSelector(getCharacter);
  const { opponent, status, results } = useSelector(getFight);
  const dispatch = useDispatch();

  const repairSupplies = getRepairSupplies(inventory.supplies);

  const activeDialog = getMobOverlayDialog(opponent.dialogs, status);

  const { currentStep, currentDialog, hasAction } = useDialog(
    activeDialog || [],
    true
  );

  return (
    <FightOverlay>
      {status === "winResults" && (
        <>
          <FightOverlayTitle>You defeated {opponent.name}!</FightOverlayTitle>
          {!!opponent.isTutorial && (
            <DialogWrapper>
              <Dialogs
                avatar={opponent.images.pilot}
                currentStep={currentStep}
                currentDialog={currentDialog}
                hasAction={hasAction}
              />
            </DialogWrapper>
          )}
          <CurrentRatingWrapper>
            <OverlaySubtitle>Battle Rating</OverlaySubtitle>
            <RatingWrapper>
              <BattleRating rating={results.battleRating} />
            </RatingWrapper>
          </CurrentRatingWrapper>
          {!!results.isLevelUp && (
            <OverlayHighlight>
              Level Up! You are now level {level}
            </OverlayHighlight>
          )}
          <RewardsWrapper>
            <OverlaySubtitle>Rewards</OverlaySubtitle>
            <OverlayList>
              {!!results.isLevelUp && (
                <ListItem>
                  <ItemWrapper>
                    <Trigger>
                      <EnhancementsImageWrapper>
                        <EnhancementsImage image={enhancementsImage} />
                      </EnhancementsImageWrapper>
                      <Tooltip>
                        <TooltipName>Medallions</TooltipName>
                        <TooltipDesc>
                          You earn medallions when you level up, more at higher
                          levels.
                        </TooltipDesc>
                        <TooltipDesc>
                          You can use medallions to either strengthen your
                          starship's core attributes or learn new pilot skills.
                          You can do this at the nearest Shipworks or Pilot
                          Academy in any city.
                        </TooltipDesc>
                      </Tooltip>
                    </Trigger>
                    <ItemName>Medallions</ItemName>
                  </ItemWrapper>
                  <ItemInfo>{results.statPoints}</ItemInfo>
                </ListItem>
              )}
              <ListItem>
                <ItemWrapper>
                  <Trigger>
                    <ExperienceImageWrapper>
                      <ExperienceImage image={experienceImage} />
                    </ExperienceImageWrapper>
                    <Tooltip>
                      <TooltipName>Experience</TooltipName>
                      <TooltipDesc>
                        Gain experience to level up, which allows you to improve
                        your starship's stats and learn more pilot skills.
                      </TooltipDesc>
                    </Tooltip>
                  </Trigger>
                  <ItemName>Experience Gained</ItemName>
                </ItemWrapper>
                <ItemInfo>{results.experience}</ItemInfo>
              </ListItem>
              <ListItem>
                <ItemWrapper>
                  <Trigger>
                    <CreditsImageWrapper>
                      <CreditsImage image={creditsImage} />
                    </CreditsImageWrapper>
                    <Tooltip>
                      <TooltipName>Credits</TooltipName>
                      <TooltipDesc>
                        Collect credits to buy upgrades to install onto your
                        starship and other useful supplies. You can do this at
                        the nearest Infinity Shop in any city.
                      </TooltipDesc>
                    </Tooltip>
                  </Trigger>
                  <ItemName>Credits Received</ItemName>
                </ItemWrapper>
                <ItemInfo>{results.credits}</ItemInfo>
              </ListItem>
              {results.drops.map((drop) => (
                <ListItem key={drop.slug}>
                  <ItemWrapper>
                    <ItemImageWrapper>
                      <ItemIcon slug={drop.slug} />
                    </ItemImageWrapper>
                    <ItemName>{getItemData(drop.slug).name}</ItemName>
                  </ItemWrapper>
                  <ItemInfo>{drop.quantity}x</ItemInfo>
                </ListItem>
              ))}
            </OverlayList>
          </RewardsWrapper>
          <QuickRepairWrapper>
            <OverlaySubtitle>Quick Repair</OverlaySubtitle>
            <RepairSupplies>
              {repairSupplies.map((supply) => (
                <Item
                  key={supply.slug}
                  onClick={() => dispatch(employSupply(supply.slug))}
                >
                  <ItemIcon slug={supply.slug} />
                  <ItemQuantity>x{supply.quantity}</ItemQuantity>
                </Item>
              ))}
            </RepairSupplies>
          </QuickRepairWrapper>
          <OverlayActions>
            <OverlayButton onClick={() => dispatch(onwardFightWin())}>
              Onward
            </OverlayButton>
            <OverlayButton isHighlight onClick={() => dispatch(fightAgain())}>
              Battle Again
            </OverlayButton>
          </OverlayActions>
        </>
      )}
      {status === "loseResults" && (
        <>
          <FightOverlayTitle>
            You were defeated by {opponent.name}.
          </FightOverlayTitle>
          {!!opponent.isTutorial && (
            <DialogWrapper>
              <Dialogs
                avatar={opponent.images.pilot}
                currentStep={currentStep}
                currentDialog={currentDialog}
                hasAction={hasAction}
              />
            </DialogWrapper>
          )}
          <OverlaySubtitle>Consequences:</OverlaySubtitle>
          <OverlayList>
            <ListItem>
              <ItemName>Experience Lost</ItemName>
              <ItemInfo>{results.experience}</ItemInfo>
            </ListItem>
          </OverlayList>
          <OverlayActions>
            <OverlayButton onClick={() => dispatch(onwardFightLose())}>
              Onward
            </OverlayButton>
          </OverlayActions>
        </>
      )}
    </FightOverlay>
  );
}
