import React from "react";
import styled, { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";

import firepowerImage from "assets/stat-firepower.svg";
import resilienceImage from "assets/stat-resilience.svg";
import speedImage from "assets/stat-speed.svg";
import precisionImage from "assets/stat-precision.svg";
import energyImage from "assets/stat-energy.svg";
import escapeImage from "assets/icon-escape.svg";
import { COLORS } from "utils/constants";
import {
  targetWeapons,
  targetShields,
  targetThrusters,
  targetTargetingComputer,
  targetReactor,
  activateSkill,
  employFightSupply,
  escapeFight,
} from "redux/actions";
import { getCharacter, getFight } from "redux/selectors";
import {
  getEscapeChance,
  getFightSupplies,
  getInventoryItemAmount,
  getStatRangeData,
  getSupplyData,
  getTotalWeakenedIntegrity,
} from "utils/stats";
import Skill from "components/base/Skill";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import ItemIcon from "components/base/ItemIcon";
import { formatPercentage } from "utils/formatters";
import { SupplyTypes } from "types";

interface FightActionsProps {
  $isEnabled: boolean;
}

interface SupplyProps {
  $isDisabled: boolean;
}

interface PartIconProps {
  image: string;
  color: string;
}

interface EscapeIconProps {
  image: string;
}

interface TooltipAttackDescProps {
  color: string;
}

const disabledMixin = css`
  opacity: 0.3;
  pointer-events: none;
  filter: saturate(0);
`;

const FightActionsWrapper = styled.div<FightActionsProps>`
  color: white;
  opacity: 0.4;
  pointer-events: none;
  ${(props) => props.$isEnabled && `opacity: 1;`}
  ${(props) => props.$isEnabled && `pointer-events: auto;`}
  transition: all 200ms ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const FightActionGroups = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Parts = styled.div`
  display: flex;
  gap: 10px;
`;

const Part = styled.div`
  background-color: #0c434c70;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0c434c;
  }
`;

const PartIcon = styled.div<PartIconProps>`
  height: 24px;
  width: 24px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const Skills = styled.div`
  display: flex;
  gap: 10px;
`;

const Supplies = styled.div`
  display: flex;
  gap: 10px;
`;

const SupplyQuantity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000090;
  color: white;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  transition: opacity 200ms ease-out;
`;

const Supply = styled.div<SupplyProps>`
  width: 44px;
  height: 44px;
  cursor: pointer;
  position: relative;

  &:hover ${SupplyQuantity} {
    opacity: 1;
  }

  ${(props) => props.$isDisabled && disabledMixin}
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

const Escape = styled.div`
  background-color: #d8000099;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #d80000;
  }
`;

const EscapeIcon = styled.div<EscapeIconProps>`
  height: 28px;
  width: 28px;
  background-color: #000000;
  mask: url(${(props) => props.image}) no-repeat center;
`;

const TooltipAttackDesc = styled(TooltipDesc)<TooltipAttackDescProps>`
  span {
    color: ${(props) => props.color};
  }

  strong {
    color: color-mix(in srgb, ${COLORS.FIREPOWER}, ${COLORS.PRECISION});
  }
`;

const TooltipFleeDesc = styled(TooltipDesc)`
  strong {
    color: ${COLORS.SPEED};
  }
`;

const TARGET_PARTS = [
  {
    name: "weapons",
    stat: "firepower",
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    action: targetWeapons,
  },
  {
    name: "shields",
    stat: "resilience",
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    action: targetShields,
  },
  {
    name: "thrusters",
    stat: "speed",
    image: speedImage,
    color: COLORS.SPEED,
    action: targetThrusters,
  },
  {
    name: "targeting grid",
    stat: "precision",
    image: precisionImage,
    color: COLORS.PRECISION,
    action: targetTargetingComputer,
  },
  {
    name: "reactor core",
    stat: "antimatter",
    image: energyImage,
    color: COLORS.ENERGY,
    action: targetReactor,
  },
];

export default function FightActions() {
  const { data: characterData } = useSelector(getCharacter);
  const {
    health,
    skillsRecharge,
    inventory,
    skills,
    stats: characterStats,
    statRanges,
    weakenedBaseStats,
  } = characterData;
  const { opponent, areActionsEnabled } = useSelector(getFight);
  const { stats: opponentStats } = opponent;
  const dispatch = useDispatch();

  const trainedSkills = skills.filter((skill) => !!skill.isTrained);
  const fightSupplies = getFightSupplies(inventory.supplies);
  const isFullHealth = health >= characterStats.maxHealth;
  const integrityToRestore = getTotalWeakenedIntegrity(weakenedBaseStats);
  const isFullIntegrity = integrityToRestore <= 0;

  const escapeChance = getEscapeChance(
    characterStats.movementSpeed,
    opponentStats.movementSpeed
  );

  const attackRangeInfo = getStatRangeData("attackDamageRange");
  const weakenRangeInfo = getStatRangeData("weakenPartsRange");
  const { attackDamageRange, weakenPartsRange } = statRanges;

  return (
    <FightActionsWrapper $isEnabled={areActionsEnabled}>
      <FightActionGroups>
        <Parts>
          {TARGET_PARTS.map((part) => (
            <Trigger key={part.name}>
              <Part onClick={() => dispatch(part.action())}>
                <PartIcon image={part.image} color={part.color} />
              </Part>
              <Tooltip isAbove>
                <TooltipName>Attack: {part.name}</TooltipName>
                <TooltipAttackDesc color={part.color}>
                  {parse(`Inflict <strong>${attackRangeInfo.formatter(
                    attackDamageRange.min,
                    attackDamageRange.max
                  )}</strong>
                   ship damage and weaken <span>${part.stat}</span> by 
                  <strong>${weakenRangeInfo.formatter(
                    weakenPartsRange.min,
                    weakenPartsRange.max
                  )}</strong>
                   by targeting ${opponent.name}'s ${part.name}. `)}
                </TooltipAttackDesc>
              </Tooltip>
            </Trigger>
          ))}
        </Parts>
        <Skills>
          {trainedSkills.map((skill) => {
            const rechargeTurns = skillsRecharge[skill.slug] || 0;
            const totalRecharge = skill.recharge || rechargeTurns;
            const rechargePercentage = (rechargeTurns / totalRecharge) * 100;
            const isRecharging = rechargeTurns > 0;
            return (
              <Skill
                key={skill.slug}
                skill={skill}
                characterData={characterData}
                isShowValues
                isTooltipAbove
                isRecharging={isRecharging}
                rechargeTurns={rechargeTurns}
                rechargePercentage={rechargePercentage}
                onClick={() =>
                  !isRecharging && dispatch(activateSkill(skill.slug))
                }
              />
            );
          })}
        </Skills>
        <Supplies>
          {Object.entries(fightSupplies).map(([key, value]) => {
            const supplyType = key as SupplyTypes;
            const supplies = value as string[];

            let isDisabled = false;
            // Disable health repair kits if at full health
            if (supplyType === "health" && isFullHealth) {
              isDisabled = true;
            }
            // Disable parts restore modules if at full parts integrity
            if (supplyType === "integrity" && isFullIntegrity) {
              isDisabled = true;
            }

            return (
              <>
                {supplies.map((supply) => {
                  const supplyData = getSupplyData(supply);
                  const quantity = getInventoryItemAmount(supply, inventory);
                  return (
                    <Supply
                      key={supplyData.slug}
                      $isDisabled={isDisabled}
                      onClick={() => dispatch(employFightSupply(supply))}
                    >
                      <ItemIcon slug={supply} isTooltipAbove />
                      <SupplyQuantity>x{quantity}</SupplyQuantity>
                    </Supply>
                  );
                })}
              </>
            );
          })}
        </Supplies>
        <Actions>
          <Trigger>
            <Escape onClick={() => dispatch(escapeFight())}>
              <EscapeIcon image={escapeImage} />
            </Escape>
            <Tooltip isAbove>
              <TooltipName>Escape</TooltipName>
              <TooltipDesc>
                Attempt to flee the battle, with your chances of success
                determined by your ship's speed relative to the enemy's.
              </TooltipDesc>
              <TooltipDesc>
                <TooltipFleeDesc>
                  {parse(`Your current chance of escape is 
                  <strong>${formatPercentage(escapeChance)}</strong>.`)}
                </TooltipFleeDesc>
              </TooltipDesc>
            </Tooltip>
          </Trigger>
        </Actions>
      </FightActionGroups>
    </FightActionsWrapper>
  );
}
