import { useDispatch, useSelector } from "react-redux";

import {
  linkUserAccount,
  signOutUserAccount,
  unlinkUserAccount,
} from "redux/actions";
import { getGameState } from "redux/selectors";
import { SettingButton, SettingName, SettingWrapper } from "./Settings";

export default function SettingsAuth() {
  const dispatch = useDispatch();

  const startLinkAccountFlow = () => {
    dispatch(linkUserAccount());
  };

  const startUnlinkAccountFlow = () => {
    dispatch(unlinkUserAccount());
  };

  const { isUserAccountLinked } = useSelector(getGameState);

  return (
    <>
      <SettingWrapper>
        <SettingName>Link Account</SettingName>
        {!isUserAccountLinked && (
          <SettingButton onClick={startLinkAccountFlow}>
            Link to Google
          </SettingButton>
        )}
        {!!isUserAccountLinked && (
          <SettingButton onClick={startUnlinkAccountFlow}>
            Unlink from Google
          </SettingButton>
        )}
      </SettingWrapper>
      <SettingWrapper>
        <SettingName>System Actions</SettingName>
        <SettingButton onClick={() => dispatch(signOutUserAccount())}>
          Log out
        </SettingButton>
      </SettingWrapper>
    </>
  );
}
