import styled, { css } from "styled-components";

interface OverlayButtonProps {
  isHighlight?: boolean;
}

const highlightMixin = css`
  background-color: #c92e39;
`;

export const FightOverlay = styled.div`
  width: 560px;
  padding: 30px;
  background-color: #0a1616;
  color: white;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  border: 1px #43fffa solid;
  border-top-width: 6px;
  pointer-events: auto;
`;

export const FightOverlayTitle = styled.div`
  text-transform: uppercase;
  color: #43fffa;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const DialogWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
`;

export const OverlayList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const OverlayHighlight = styled.div`
  text-transform: uppercase;
  color: #e3cb44;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const OverlaySubtitle = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemName = styled.div``;

export const ItemInfo = styled.div``;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ItemImageWrapper = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 4px;
`;

export const OverlayActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const OverlayButton = styled.div<OverlayButtonProps>`
  cursor: pointer;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
  background-color: #248791;
  padding: 5px 10px;
  ${(props) => !!props.isHighlight && highlightMixin}

  &:only-child {
    margin: 0 auto;
  }

  &:hover {
    filter: brightness(120%);
  }
`;
