import React from "react";
import styled from "styled-components";

import creditsImage from "assets/credits.svg";
import { getInventoryItemAmount, getItemData } from "utils/stats";
import ItemIcon from "components/base/ItemIcon";
import { COLORS } from "utils/constants";
import { useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";

interface ShopItemProps {
  slug: string;
  isBuy: boolean;
  onClick: (x: string) => void;
}

interface IconProps {
  image: string;
}

export const ActionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  opacity: 0;
  transition: opacity 200ms ease-out;
  pointer-events: none;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  cursor: pointer;

  &:hover ${ActionWrapper} {
    opacity: 1;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ActionButton = styled.div`
  height: 100%;
  background-color: white;
  mask: url(${creditsImage}) no-repeat center;
  mask-size: 60%;
`;

export const ItemImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ItemName = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const Description = styled.div`
  font-size: 14px;
`;

export const ItemCredits = styled.div`
  display: flex;
  gap: 2px;
  color: ${COLORS.CREDITS};
  align-items: center;
  font-size: 16px;
  text-align: right;
`;

export const ItemCreditsIcon = styled.div<IconProps>`
  height: 14px;
  width: 14px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

export default function ShopItem({ slug, isBuy, onClick }: ShopItemProps) {
  const {
    data: { inventory },
  } = useSelector(getCharacter);

  const itemData = getItemData(slug);
  const quantity = getInventoryItemAmount(slug, inventory);

  return (
    <Item key={itemData.name} onClick={() => onClick(slug)}>
      <ItemWrapper>
        <ItemImageWrapper>
          <ItemIcon slug={slug} />
          <ActionWrapper>
            <ActionButton />
          </ActionWrapper>
        </ItemImageWrapper>
        <ItemInfo>
          <ItemName>{itemData.name}</ItemName>
          {!!quantity && <Description>In Inventory: x{quantity}</Description>}
        </ItemInfo>
      </ItemWrapper>
      <ItemCredits>
        <ItemCreditsIcon image={creditsImage} />
        {!!isBuy ? itemData.credits : itemData.sellCredits}
      </ItemCredits>
    </Item>
  );
}
