import { keyframes } from "styled-components";
import { COLORS } from "utils/constants";

export const backgroundAnimation = keyframes`
  0% {
    transform: scale(1) rotate(0deg);
    filter: saturate(100%);
  }

  50% {
    transform: scale(1.2) rotate(5deg);
    filter: saturate(150%);
  }

  100% {
    transform: scale(1) rotate(0deg);
    filter: saturate(100%);
  }
`;

export const backgroundMoveRightAnimation = keyframes`
  0% {
    transform: translate3d(-10%, 0, 0);
  }

  80% {
    transform: translate3d(110%, 0, 0);
  }

  100% {
    transform: translate3d(110%, 0, 0);
  }
`;

export const backgroundMoveLeftAnimation = keyframes`
  0% {
    transform: translate3d(10%, 0, 0) scaleX(-1);
  }

  80% {
    transform: translate3d(-110%, 0, 0) scaleX(-1);
  }

  100% {
    transform: translate3d(-110%, 0, 0) scaleX(-1);
  }
`;

export const floatAnimation = keyframes`
  0% {
    transform: translate3d(0, -3%, 0);
  }

  50% {
    transform: translate3d(0, 3%, 0);
  }

  100% {
    transform: translate3d(0, -3%, 0);
  }
`;

export const wanderAnimation = keyframes`
  0% {
    transform: translate3d(20vw, 0, 0);
  }

  50% {
    transform: translate3d(-20vw, 0, 0);
  }

  100% {
    transform: translate3d(20vw, 0, 0);
  }
`;

export const wanderTurnAnimation = keyframes`
  0% {
    transform: scaleX(-1);
  }

  50% {
    transform: scaleX(-1);
  }

  50.01% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(1);
  }
`;

export const glowAnimation = keyframes`
  0% {
    transform-style: preserve-3d;
    filter: brightness(100%);
  }

  50% {
    filter: brightness(130%);
  }

  100% {
    filter: brightness(100%);
  }
`;

export const thrusterAnimation = keyframes`
  0% {
    transform: translate3d(0, -50%, 0) scale(100%);
  }

  25% {
    transform: translate3d(0, -50%, 0) scale(120%);
  }

  50% {
    transform: translate3d(0, -50%, 0) scale(105%);
  }

  75% {
    transform: translate3d(0, -50%, 0) scale(110%);
  }

  100% {
    transform: translate3d(0, -50%, 0) scale(100%);
  }
`;

export const attackAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: translate3d(4000%, -50%, 0);
  }
`;

export const mobAttackAnimation = keyframes`
0% {
  opacity: 0;
  transform: translate3d(0, -50%, 0) scaleX(-1);
}

10% {
  opacity: 1;
}

90% {
  opacity: 1;
}

100% {
  opacity: 0;
  transform: translate3d(-4000%, -50%, 0) scaleX(-1);
}
`;

export const damagedAnimation = keyframes`
  0% { transform: translate3d(1px, 1px, 0) rotate(0deg); }
  10% { transform: translate3d(-1px, -2px, 0) rotate(-2deg); }
  20% { transform: translate3d(-3px, 0px, 0) rotate(2deg); }
  30% { transform: translate3d(3px, 2px, 0) rotate(0deg); }
  40% { transform: translate3d(1px, -1px, 0) rotate(2deg); }
  50% { transform: translate3d(-1px, 2px, 0) rotate(-2deg); }
  60% { transform: translate3d(-3px, 1px, 0) rotate(0deg); }
  70% { transform: translate3d(3px, 1px, 0) rotate(-2deg); }
  80% { transform: translate3d(-1px, -1px, 0) rotate(2deg); }
  90% { transform: translate3d(1px, 2px, 0) rotate(0deg); }
  100% { transform: translate3d(1px, -2px, 0) rotate(-2deg); }
`;

export const mobDamagedAnimation = keyframes`
  0% { transform: translate3d(1px, 1px, 0) rotate(0deg); }
  10% { transform: translate3d(-1px, -2px, 0) rotate(-2deg); }
  20% { transform: translate3d(-3px, 0px, 0) rotate(2deg); }
  30% { transform: translate3d(3px, 2px, 0) rotate(0deg); }
  40% { transform: translate3d(1px, -1px, 0) rotate(2deg); }
  50% { transform: translate3d(-1px, 2px, 0) rotate(-2deg); }
  60% { transform: translate3d(-3px, 1px, 0) rotate(0deg); }
  70% { transform: translate3d(3px, 1px, 0) rotate(-2deg); }
  80% { transform: translate3d(-1px, -1px, 0) rotate(2deg); }
  90% { transform: translate3d(1px, 2px, 0) rotate(0deg); }
  100% { transform: translate3d(1px, -2px, 0) rotate(-2deg); }
`;

export const dodgeAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -50px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const mobDodgeAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -50px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const weakenedAnimation = keyframes`
  0% {
    transform: scale(.5);
    opacity: .5;
  }

  75% {
    opacity: .5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const mobWeakenedAnimation = keyframes`
  0% {
    transform: scale(.5);
    opacity: .5;
  }

  75% {
    opacity: .5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const nullifyAnimation = keyframes`
  0% {
    transform: scale(1) rotate(0);
    filter: brightness(100%) saturate(100%);
    
  }

  50% {
    transform: scale(1.2) rotate(90deg);
    filter: brightness(120%) saturate(200%);
  }

  100% {
    transform: scale(1) rotate(180deg);
    filter: brightness(100%) saturate(100%);
  }
`;

export const mobNullifyAnimation = keyframes`
  0% {
    transform: scale(1) rotate(0);
    filter: brightness(100%) saturate(100%);
  }

  50% {
    transform: scale(1.2) rotate(-90deg);
    filter: brightness(120%) saturate(200%);
  }

  100% {
    transform: scale(1) rotate(-180deg);
    filter: brightness(100%) saturate(100%);
  }
`;

export const escapeAnimation = keyframes`
  0% {
    transform: translateX(0%) scaleX(-1);
  }

  50% {
    transform: translateX(50%) scaleX(-1);
  }

  100% {
    transform: translateX(0%) scaleX(-1);
  }
`;

export const loseAnimation = keyframes`
  0% {
    top: 0;
  }

  5% { transform: translate3d(1px, 1px, 0) rotate(0deg); }
  10% { transform: translate3d(-1px, -2px, 0) rotate(-2deg); }
  15% { transform: translate3d(-3px, 0px, 0) rotate(2deg); }
  20% { transform: translate3d(3px, 2px, 0) rotate(0deg); }
  25% { transform: translate3d(1px, -1px, 0) rotate(2deg); }
  30% { transform: translate3d(-1px, 2px, 0) rotate(-2deg); }
  35% { transform: translate3d(-3px, 1px, 0) rotate(0deg); }
  40% { transform: translate3d(3px, 1px, 0) rotate(-2deg); }
  45% { transform: translate3d(-1px, -1px, 0) rotate(2deg); }

  50% {
    top: 200px; 
    transform: translate3d(0, 0, 0) rotate(0deg);
  }

  80% {
    top: 200px;
  }

  100% {
    top: 0;
  }
`;

export const mobLoseAnimation = keyframes`
  0% {
    top: 0;
  }

  5% { transform: translate3d(2px, 1px, 0) rotate(0deg); }
  10% { transform: translate3d(-2px, -2px, 0) rotate(-2deg); }
  15% { transform: translate3d(-3px, 0px, 0) rotate(2deg); }
  20% { transform: translate3d(3px, 4px, 0) rotate(0deg); }
  25% { transform: translate3d(-1px, -1px, 0) rotate(2deg); }
  30% { transform: translate3d(3px, 2px, 0) rotate(-2deg); }
  35% { transform: translate3d(-3px, 1px, 0) rotate(0deg); }
  40% { transform: translate3d(4px, 1px, 0) rotate(-2deg); }
  45% { transform: translate3d(-1px, -2px, 0) rotate(2deg); }
  
  50% { 
    top: 200px; 
    transform: translate3d(0, 0, 0) rotate(0deg); 
  }

  80% {
    top: 200px;
  }

  100% {
    top: 0;
  }
`;

export const rapidFireAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(.2) rotate(0deg);
  }

  10% {
    opacity: 1;
  }

  80% {
    opacity: 1;
    transform: scale(.75) rotate(2880deg);
  }

  100% {
    opacity: 0;
    transform: scale(0) rotate(4320deg);
  }
`;

export const energyBlastAnimation = keyframes`
  0% {
    opacity: .2;
    transform: scale(.5);
  }

  80% {
    opacity: .8;
    filter: saturate(150%) brightness(150%);
  }

  100% {
    opacity: 0;
    transform: scale(10);
    border-width: 10px;
  }
`;

export const reactorOverloadAnimation = keyframes`
  0% {
    opacity: 0;
    background-color: ${COLORS.ENERGY};
    transform: scale(.5);
  }  

  20% {
    opacity: 0;
  }

  30% {
    transform: scale(0.5);
    filter: saturate(150%) brightness(150%);
    background-color: ${COLORS.ENERGY};
  }

  90% {
    opacity: 1;
    background-color: #ffffff;
  }

  100% {
    opacity: 0;
    transform: scale(16);
    filter: saturate(150%) brightness(150%);
  }
`;

export const shieldBreakerAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scaleX(1) scaleY(0);
    background-color: ${COLORS.FIREPOWER}30;
    box-shadow: 0 0px 20px ${COLORS.FIREPOWER};
  }

  20% {
    opacity: .8;
    transform: scaleX(10) scaleY(.5);
  }

  90% {
    opacity: 1;
    transform: scaleX(10) scaleY(.2);
    background-color: ${COLORS.FIREPOWER}70;
    box-shadow: 0 0px 200px ${COLORS.FIREPOWER};
    border-width: 20px;
  }

  100% {
    opacity: 0;
    transform: scaleX(10) scaleY(0);
  }
`;

export const scattershotAnimation = keyframes`
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 0;
    border-width: 1px;
  }

  10% {
    opacity: 0.8;
  }

  50% {
    transform: scale(1) rotate(180deg);
  }

  90% {
    opacity: 0.8;
  }

  100% {
    transform: scale(10) rotate(540deg);
    opacity: 0;
    border-width: 20px;
  }
`;

export const powerSurgeAnimation = keyframes`
  0% {
    transform: rotate(0deg) scale(0);
    border: .5px ${COLORS.FIREPOWER}20 solid;
    border-radius: 50%;
    background-color: ${COLORS.FIREPOWER}80;
  }

  20% {
    transform: rotate(360deg) scale(0.8);
    border-radius: 30%;
  }

  40% {
    transform: rotate(720deg) scale(1.5);
    border-radius: 40%;
  }

  60% {
    transform: rotate(900deg) scale(1.2);
    border-radius: 35%;
  }

  90% {
    transform: rotate(1440deg) scale(2);
    border-radius: 45%;
  }

  95% {
    opacity: 1;
    background-color: ${COLORS.FIREPOWER}0;
  }
    
  100% {
    border: .5px ${COLORS.FIREPOWER} solid;
    border-radius: 50%;
    background-color: ${COLORS.FIREPOWER}0;
    transform: rotate(1800deg) scale(0);
    opacity: 0;
  }
`;

export const reactorOverloadShipAnimation = keyframes`
  0% { transform: translate3d(4px, 5px, 0) rotate(0deg); }
  10% { transform: translate3d(-8px, -6px, 0) rotate(-2deg); }
  20% { transform: translate3d(10px, 3px, 0) rotate(2deg); }
  30% { transform: translate3d(-3px, 6px, 0) rotate(0deg); }
  40% { transform: translate3d(8px, -5px, 0) rotate(2deg); }
  50% { transform: translate3d(-4px, 7px, 0) rotate(-2deg); }
  60% { transform: translate3d(10px, 1px, 0) rotate(0deg); }
  70% { transform: translate3d(2px, 4px, 0) rotate(-2deg); }
  80% { transform: translate3d(-9px, -5px, 0) rotate(2deg); }
  90% { transform: translate3d(4px, 5px, 0) rotate(0deg); }
  100% { transform: translate3d(-5px, -3px, 0) rotate(-2deg); }
`;

export const shieldBypassAnimation = keyframes`
  0% {
    opacity: 0;
    width: 100%;
    background-color: ${COLORS.ENERGY}20;
    border-width: 1px;
    filter: saturate(100%);
  }  

  10% {
    opacity: 1;
    width: 100%;
    background-color: ${COLORS.ENERGY}20;
    border-width: 1px;
    filter: saturate(100%);
  }

  90% {
    opacity: 1;
    width: 300vw;
    background-color: ${COLORS.ENERGY}80;
    border-width: 10px;
    filter: saturate(150%);
  }

  100% {
    opacity: 0;
    width: 300vw;
    height: 120%;
    background-color: ${COLORS.ENERGY}80;
    border-width: 10px;
    filter: saturate(150%);
  }
`;

export const systemsDecayAnimation = keyframes`
  0% {
    opacity: .2;
    transform: scale(.5) rotate(0deg);
  }

  80% {
    opacity: .8;
  }

  100% {
    opacity: 0;
    transform: scale(10) rotate(720deg);
  }
`;

export const skillsRechargeAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate(0deg);
    border-width: 1px;
    border-radius: 40%;
  }

  10% {
    opacity: .75;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(1080deg);
    border-width: 200px;
    border-radius: 50%;
  }
`;

export const pressurePointAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate(0deg) scaleX(0);
    filter: brightness(100%) saturate(100%);
  }

  10% {
    opacity: .5;
    transform: rotate(-5deg) scaleX(10);
  }

  20% {
    transform: rotate(3deg) scaleX(10);
  }

  30% {
    transform: rotate(-4deg) scaleX(10);
  }

  40% {
    transform: rotate(2deg) scaleX(10);
  }

  50% {
    opacity: .5;
    transform: rotate(0deg) scaleX(10);
    filter: brightness(100%) saturate(100%);
    box-shadow: 0 0px 0px ${COLORS.PRECISION};
  }

  90% {
    opacity: 1;
    transform: rotate(0deg) scaleX(10);
    filter: brightness(150%) saturate(150%);
    box-shadow: 0 0px 5px ${COLORS.PRECISION};
  }

  100% {
    opacity: 0;
  }
`;

export const eagleEyeAnimation = keyframes`
  0% {
    transform: translateX(0) scale(1);
    opacity: 0;
    border-width: 2px;
  }

  10% {
    opacity: .5;
  }

  90% {
    opacity: .5;
  }

  100% {
    transform: translateX(200%) scale(4);
    opacity: 0;
    border-width: 5px;
  }
`;

export const blastEchoAnimation = keyframes`
  0% {
    transform: translateX(0) scale(.2);
    opacity: .5;
    box-shadow: -10px 0px 5px ${COLORS.PRECISION};
  }

  49% {
    box-shadow: -10px 0px 5px ${COLORS.PRECISION};
  }

  50% {
    opacity: .75;
    transform: translateX(500%) scale(2);
  }

  51% {
    box-shadow: -10px 0px 5px white;
  }

  100% {
    transform: translateX(0) scale(.2);
    opacity: .5;
    box-shadow: -10px 0px 5px white;
  }
`;

export const phantomStrikeTrailAnimation = keyframes`
  0% {
    transform: scaleX(.2) scaleY(1);
    opacity: 0;
  }

  30% {
    transform: scaleX(.2) scaleY(1);
    opacity: 0;
  }

  50% {
    transform: scaleX(6) scaleY(0.5);
    opacity: 1;
  }

  100% {
    transform: scaleX(0) scaleY(1);
  }
`;

export const phantomStrikeShipAnimation = keyframes`
  0% {
    transform: translateX(0) scaleY(1);
  }

  30% {
    transform: translateX(-20%) scaleY(1);
  }

  50% {
    transform: translateX(500%) scaleY(.8);
    opacity: 1;
  }

  51% {
    transform: translateX(500%) scaleY(1);
    opacity: 0;
  }

  79% {
    opacity: 0;
  }

  80% {
    transform: translateX(-200%) scaleY(1);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) scaleY(1);
  }
`;

export const hyperShiftEffectAnimation = keyframes`
  0% {
    transform: scaleY(.2);
    opacity: 0;
  }

  10% {
    transform: scaleY(2);
    opacity: 1;
  }

  50% {
    transform: scaleY(2.5) ;
    opacity: 1;
  }

  100% {
    transform: scaleY(1);
    opacity: 0;
  }
`;

export const hyperShiftShipAnimation = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  10% {
    transform: translateX(-20%);
    opacity: 0
  }

  15% {
    transform: translateX(20%);
    opacity: 1
  }

  20% {
    transform: translateX(-30%);
    opacity: .2
  }

  25% {
    transform: translateX(40%);
    opacity: 1;
  }

  30% {
    transform: translateX(-10%);
    opacity: 0;
  }

  35% {
    transform: translateX(-50%);
    opacity: 0.5;
  }

  40% {
    transform: translateX(50%);
    opacity: 0;
  }

  95% {
    transform: translateX(-20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const emergencyRepairsAnimation = keyframes`
  0% {
    opacity: .2;
    transform: scaleY(6) scaleX(0);
  }

  30% {
    opacity: .6;
    transform: scaleY(6) scaleX(1.5);
  }

  90% {
    opacity: 1;
    transform: scaleY(6) scaleX(2);
  }

  100% {
    opacity: 0;
    transform: scaleY(6) scaleX(0);
  }
`;

export const shieldRestoreAnimation = keyframes`
  0% {
    border-radius: 50%;
    transform: rotate(0deg) scale(1);
  }

  10% {
    transform: rotate(180deg) scale(1.2);
    box-shadow: inset 0 0 5px 10px ${COLORS.RESILIENCE}20, 0 0 5px 10px ${COLORS.RESILIENCE}10;
  }

  20% {
    transform: rotate(360deg) scale(.8);
  }

  80% {
    transform: rotate(1080deg) scale(.8);
  }

  90% {
    transform: rotate(1440deg) scale(1.2);
    box-shadow: inset 0 0 30px 60px ${COLORS.RESILIENCE}80, 0 0 30px 60px ${COLORS.RESILIENCE}60;
  }

  100% {
    border-radius: 50%;
    transform: rotate(0deg) scale(1);
  }
`;

export const shieldStormAnimation = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }

  40% {
    transform: rotate(180deg) scale(.8);
    filter: saturate(150%) brightness(150%);
  }

  90% {
    transform: rotate(720deg) scale(10);
    filter: saturate(150%) brightness(150%);
    opacity: 1;
  }

  91% {
    transform: rotate(720deg) scale(10);
    filter: saturate(150%) brightness(150%);
    opacity: 0;
  }

  92% {
    transform: rotate(0deg) scale(1);
    filter: saturate(100%) brightness(100%);
    opacity: 0; 
  }

  100% {
    transform: rotate(0deg) scale(1);
    filter: saturate(100%) brightness(100%);
    opacity: 1;
  }
`;

export const pilotBounceAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const ratingAnimation = keyframes`
  0% {
    transform: scale(1);
    filter: brightness(100%) saturate(100%);
  }

  50% {
    transform: scale(1.05);
    filter: brightness(110%) saturate(150%);
  }

  100% {
    transform: scale(1);
    filter: brightness(100%) saturate(100%);
  }
`;

export const reactorCoreAnimation = keyframes`
  0% {
    transform: scale(0); 
    opacity: 1;
  }

  75% {
    opacity: .5;
  }
  
  100% {
    transform: scale(1); 
    opacity: 0;
  }
`;

export const shieldsAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const mobShieldsAnimation = keyframes`
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const targetingSystemAnimation = keyframes`
  0% {
    transform: translate3d(0, -50%, 0) scaleY(100%);
  }

  50% {
    transform: translate3d(0, -50%, 0) scaleY(150%);
  }

  100% {
    transform: translate3d(0, -50%, 0) scaleY(100%);
  }
`;

export const mobTargetingSystemAnimation = keyframes`
  0% {
    transform: translate3d(0, -50%, 0) scaleY(100%) scaleX(-1);
  }

  50% {
    transform: translate3d(0, -50%, 0) scaleY(150%) scaleX(-1);
  }

  100% {
    transform: translate3d(0, -50%, 0) scaleY(100%) scaleX(-1);
  }
`;

export const weaponsPreviewAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate3d(500%, -50%, 0);
  }
`;

export const upgradesScreenAnimation = keyframes`
  0% {
    background-position: calc(2em + 1px) center;
  }

  100% {
    background-position: 0em center;

  }
`;

export const pilotScreenAnimation = keyframes`
  0% {
    background-position: center calc(2em + 1px);
  }

  100% {
    background-position: center 0em;

  }
`;

export const dialogNextAnimation = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(15%);
  }

  100% {
    transform: translateX(0);
  }
`;

export const titleAnimation = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
`;
