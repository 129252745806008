import { MobModel } from "types";

import { BASE_STATS } from "data/baseStats";
import conwayShipImage from "assets/mob-conway-ship.svg";
import conwayPilotImage from "assets/mob-conway-pilot.svg";
import grenShipImage from "assets/mob-gren-ship.svg";
import grenPilotImage from "assets/mob-gren-pilot.svg";
import ardinShipImage from "assets/mob-ardin-ship.svg";
import ardinPilotImage from "assets/mob-ardin-pilot.svg";
import sharperShipImage from "assets/mob-sharper-ship.svg";
import sharperPilotImage from "assets/mob-sharper-pilot.svg";
import crossShipImage from "assets/mob-cross-ship.svg";
import crossPilotImage from "assets/mob-cross-pilot.svg";
import ketherShipImage from "assets/mob-kether-ship.svg";
import ketherPilotImage from "assets/mob-kether-pilot.svg";
import gammaShipImage from "assets/mob-gamma-ship.svg";
import gammaPilotImage from "assets/mob-gamma-pilot.svg";
import lockheedShipImage from "assets/mob-lockheed-ship.svg";
import lockheedPilotImage from "assets/mob-lockheed-pilot.svg";
import rinconShipImage from "assets/mob-rincon-ship.svg";
import rinconPilotImage from "assets/mob-rincon-pilot.svg";
import hadronShipImage from "assets/mob-hadron-ship.svg";
import hadronPilotImage from "assets/mob-hadron-pilot.svg";
import acelaShipImage from "assets/mob-acela-ship.svg";
import acelaPilotImage from "assets/mob-acela-pilot.svg";
import megamaShipImage from "assets/mob-megama-ship.svg";
import megamaPilotImage from "assets/mob-megama-pilot.svg";
import breedenShipImage from "assets/mob-breeden-ship.svg";
import breedenPilotImage from "assets/mob-breeden-pilot.svg";
import revinShipImage from "assets/mob-revin-ship.svg";
import revinPilotImage from "assets/mob-revin-pilot.svg";
import valeraShipImage from "assets/mob-valera-ship.svg";
import valeraPilotImage from "assets/mob-valera-pilot.svg";

interface MobData {
  [key: string]: MobModel;
}

const { FIREPOWER, RESILIENCE, SPEED, PRECISION, ENERGY } = BASE_STATS;

export const MOBS: MobData = {
  conway: {
    name: "Conway",
    images: {
      ship: conwayShipImage,
      pilot: conwayPilotImage,
    },
    dialogs: {
      opening:
        "Hey there, champ. Before you venture out into the vast universe, let's review the battle mechanics you learned at the academy.",
      win: "Feel free to keep battling me for practice, or go on ahead to Bishop City and explore the planet!",
      lose: "You'll now be sent to Bishop Shipworks for repairs, but I think you're ready to go out there and battle others! ...Ready-ish, at least.",
      tutorial: {
        preview:
          "Before a battle, analyze how your stats compare with your opponent. If you win, you will receive rewards based on your battle rating, which depends on how soundly you defeat your opponent.",

        fight: [
          "That green bar to the top left is your ship health. If it goes to zero first, you lose the battle.",
          "Those colorful bars on the bottom show you the how weakened each ship's parts are during battle, which affect its corresponding stat. Example: Weakened weapons mean less firepower. These are fully restored after every battle.",
          "Above that is your fight actions bar. You can hover over each button to see details. The first five buttons are regular attacks, each targeting a specific ship part.",
          "Next are your pilot skills, which have recharge periods after use. Come see me again at the Pilot Guild in the city when you're ready to learn more skills.",
          "Next are supplies you can use to repair your ship health or parts in battle. And lastly, the red button at the end is to attempt an escape.",
          "And that's it! Let's battle!",
        ],
        winResults:
          "If you win in battle, which you did this time, you gain experience and credits. The higher your battle rating, the better your rewards. If you lose in battle, you give up a bit of experience.",
        loseResults:
          "If you lose in battle, which you did this time, you give up a bit of experience. If you win in battle, you gain experience and credits. The higher your battle rating, the better your rewards.",
      },
    },
    position: 70,
    heightPercentage: 30,
    level: 1,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [PRECISION, RESILIENCE, FIREPOWER, SPEED, ENERGY],
    isBoss: false,
    isTutorial: true,
    drops: {
      bronze: {
        slug: "minor_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 60.5, y: 56.5 },
      targeting: { x: 70.5, y: 56.5 },
      thrusters: { x: 24, y: 50.75 },
    },
  },
  gren: {
    name: "Gren",
    images: {
      ship: grenShipImage,
      pilot: grenPilotImage,
    },
    dialogs: {
      opening:
        "I annihilate anyone who dares challenge me. But also please don't challenge me.",
      win: "You win this time, but I'll annihilate you next time. But also please stop challenging me.",
      lose: "I actually beat you!? I mean, of course I did.",
    },
    position: 60,
    heightPercentage: 30,
    level: 1,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, SPEED, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "minor_exp_booster",
        quantity: 1,
      },
      gold: {
        slug: "minor_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 60, y: 55.5 },
      targeting: { x: 70, y: 55.5 },
      thrusters: { x: 31.5, y: 50.75 },
    },
  },
  ardin: {
    name: "Ardin",
    images: {
      ship: ardinShipImage,
      pilot: ardinPilotImage,
    },
    dialogs: {
      opening:
        "Okay yeah, let's fight. It'll make look good in front of the others when I destroy you.",
      win: "You embarrassed me in front of everybody, I might get demoted ...again.",
      lose: "Woo, I did it! Maybe this will earn me a promotion.",
    },
    position: 90,
    heightPercentage: 30,
    level: 2,
    baseStatsModifiers: {
      firepower: 5,
      resilience: 1,
      speed: 2,
      precision: 2,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 3,
    },
    baseStatsTargets: [FIREPOWER, RESILIENCE, SPEED, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "pulse_laser",
        quantity: 1,
      },
      gold: {
        slug: "minor_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "pulse_laser",
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 45, y: 55 },
      targeting: { x: 63, y: 55 },
      thrusters: { x: 29, y: 49.5 },
    },
  },
  sharper: {
    name: "Sharper",
    images: {
      ship: sharperShipImage,
      pilot: sharperPilotImage,
    },
    dialogs: {
      opening: "Ugh, you're challenging me to a fight? I'm trying to nap here.",
      win: "Okay, yay, you defeated me. Now go away and let me sleep.",
      lose: "I told you to leave me alone.",
    },
    position: 120,
    heightPercentage: 30,
    level: 3,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 1,
      speed: 1,
      precision: 5,
      energy: 1,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 1,
      energy: 3,
    },
    baseStatsTargets: [SPEED, FIREPOWER, PRECISION, RESILIENCE, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "targeting_chip",
        quantity: 1,
      },
      gold: {
        slug: "minor_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: "targeting_chip",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 68, y: 55.75 },
      targeting: { x: 77, y: 55.75 },
      thrusters: { x: 23.6, y: 49.75 },
    },
  },
  cross: {
    name: "Cross",
    images: {
      ship: crossShipImage,
      pilot: crossPilotImage,
    },
    dialogs: {
      opening: "YEAH LET'S FIGHT LET'S GO RIGHT NOW FIGHT FIGHT FIGHT",
      win: "I DIDN'T EXPECT THAT BUT OKAY",
      lose: "LET'S GO AGAIN",
    },
    position: 150,
    heightPercentage: 30,
    level: 4,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 1,
      speed: 2,
      precision: 0,
      energy: 5,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 1,
    },
    baseStatsTargets: [ENERGY, SPEED, FIREPOWER, PRECISION, RESILIENCE],
    isBoss: false,
    drops: {
      platinum: {
        slug: "wave_cell",
        quantity: 1,
      },
      gold: {
        slug: "minor_restore_module",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: "wave_cell",
    },
    partPositions: {
      weapons: { x: 67, y: 55.25 },
      targeting: { x: 77, y: 55.25 },
      thrusters: { x: 26.2, y: 49 },
    },
  },
  kether: {
    name: "Kether",
    images: {
      ship: ketherShipImage,
      pilot: ketherPilotImage,
    },
    dialogs: {
      opening: "I'm gonna fly circles around you, slowpoke.",
      win: "I'm not that... fast? Oh my god, my whole identity.",
      lose: "I told you, sluggo.",
    },
    position: 180,
    heightPercentage: 30,
    level: 6,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 0,
      speed: 5,
      precision: 2,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 1,
      precision: 3,
      energy: 2,
    },
    baseStatsTargets: [ENERGY, PRECISION, SPEED, RESILIENCE, FIREPOWER],
    isBoss: false,
    drops: {
      platinum: {
        slug: "flash_rocket",
        quantity: 1,
      },
      gold: {
        slug: "minor_restore_module",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: "flash_rocket",
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 56, y: 53.25 },
      targeting: { x: 66, y: 53.25 },
      thrusters: { x: 35.2, y: 49.5 },
    },
  },
  gamma: {
    name: "Gamma",
    images: {
      ship: gammaShipImage,
      pilot: gammaPilotImage,
    },
    dialogs: {
      opening:
        "My ship can take a million hits, you sure you want to fight me?",
      win: "Okay fine, my ship can take less than a million hits, apparently.",
      lose: "Come back when you're actually ready to do some real damage.",
    },
    position: 210,
    heightPercentage: 30,
    level: 8,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 5,
      speed: 0,
      precision: 2,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 1,
      speed: 3,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, ENERGY, SPEED, PRECISION],
    isBoss: true,
    drops: {
      platinum: {
        slug: "guardian_shield",
        quantity: 1,
      },
      gold: {
        slug: "medium_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: "guardian_shield",
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 80, y: 57.75 },
      targeting: { x: 91, y: 57.75 },
      thrusters: { x: 13, y: 48.5 },
    },
  },
  lockheed: {
    name: "Lockheed",
    images: {
      ship: lockheedShipImage,
      pilot: lockheedPilotImage,
    },
    dialogs: {
      opening: "No one ever wants to fight me. Sure, why not?",
      win: "That was fun, I haven't felt that much excitement in ages.",
      lose: "I guess that's why nobody challenges me.",
    },
    position: 250,
    heightPercentage: 30,
    level: 10,
    baseStatsModifiers: {
      firepower: 4,
      resilience: 1,
      speed: 2,
      precision: 3,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 3,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, PRECISION, ENERGY, SPEED],
    isBoss: true,
    drops: {
      platinum: {
        slug: "firebrand",
        quantity: 1,
      },
      gold: {
        slug: "major_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "firebrand",
      shields: "bishop_shield_one",
      thrusters: "bishop_thruster_one",
      targetingSystem: "targeting_chip",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 52, y: 57.75 },
      targeting: { x: 61.5, y: 57.75 },
      thrusters: { x: 24.2, y: 52.25 },
    },
  },
  rincon: {
    name: "Rincon",
    images: {
      ship: rinconShipImage,
      pilot: rinconPilotImage,
    },
    dialogs: {
      opening:
        "Welcome to the Straylight headquarters. I might be the lowest ranked one here, but don't underestimate me.",
      win: "Seems like you estimated me just about right.",
      lose: "I told you, you should've over...estimated me.",
    },
    position: 60,
    heightPercentage: 30,
    level: 11,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, SPEED, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "major_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 64, y: 56.25 },
      targeting: { x: 74.5, y: 56.25 },
      thrusters: { x: 27.5, y: 50.75 },
    },
  },
  hadron: {
    name: "Hadron",
    images: {
      ship: hadronShipImage,
      pilot: hadronPilotImage,
    },
    dialogs: {
      opening:
        "I guess have enough ...energy... to fight you. Get it? Because my ship has a lot of antimatter energy.",
      win: "I should stop working on puns and work more on my ship.",
      lose: "You didn't get it.",
    },
    position: 90,
    heightPercentage: 30,
    level: 12,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 2,
      speed: 1,
      precision: 1,
      energy: 4,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 1,
    },
    baseStatsTargets: [ENERGY, SPEED, FIREPOWER, PRECISION, RESILIENCE],
    isBoss: false,
    drops: {
      platinum: {
        slug: "velo_nexus",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: "velo_nexus",
    },
    partPositions: {
      weapons: { x: 71, y: 56.75 },
      targeting: { x: 82, y: 56.75 },
      thrusters: { x: 21.5, y: 50 },
    },
  },
  acela: {
    name: "Acela",
    images: {
      ship: acelaShipImage,
      pilot: acelaPilotImage,
    },
    dialogs: {
      opening:
        "They call me: Death by a Thousand Laser... Cuts. We're workshopping it.",
      win: "I guess my new nickname should be: I Suck. I'm not good at nicknames.",
      lose: "Okay that's done, now I can go back to workshopping nicknames. You got any ideas?",
    },
    position: 120,
    heightPercentage: 30,
    level: 13,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 2,
      speed: 5,
      precision: 0,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 1,
      precision: 3,
      energy: 2,
    },
    baseStatsTargets: [SPEED, ENERGY, PRECISION, RESILIENCE, FIREPOWER],
    isBoss: false,
    drops: {
      platinum: {
        slug: "hyperburner",
        quantity: 1,
      },
      gold: {
        slug: "medium_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: "hyperburner",
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 63, y: 55.25 },
      targeting: { x: 73, y: 55.25 },
      thrusters: { x: 29, y: 50.25 },
    },
  },
  megama: {
    name: "Megama",
    images: {
      ship: megamaShipImage,
      pilot: megamaPilotImage,
    },
    dialogs: {
      opening:
        "Can your weapons even pierce my dope as hell shields? You're welcome to try.",
      win: "I guess I need to upgrade my shields.",
      lose: "I tried to warn you about my shields. I'm pretty into my shields as you can tell.",
    },
    position: 150,
    heightPercentage: 30,
    level: 14,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 5,
      speed: 1,
      precision: 1,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 1,
      speed: 3,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, ENERGY, SPEED, PRECISION],
    isBoss: true,
    drops: {
      platinum: {
        slug: "light_sentinel",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: "light_sentinel",
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 80, y: 59.75 },
      targeting: { x: 91, y: 59.75 },
      thrusters: { x: 13, y: 50.75 },
    },
  },
  breeden: {
    name: "Breeden",
    images: {
      ship: breedenShipImage,
      pilot: breedenPilotImage,
    },
    dialogs: {
      opening: "Precision is the best attribute a pilot can have.",
      win: "Precisely.",
      lose: "Maybe being precise isn't all that useful. Oh my god, my identity.",
    },
    position: 180,
    heightPercentage: 30,
    level: 16,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 2,
      speed: 1,
      precision: 4,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 1,
      energy: 3,
    },
    baseStatsTargets: [SPEED, FIREPOWER, PRECISION, RESILIENCE, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "stellar_sight",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: "stellar_sight",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 71, y: 58.5 },
      targeting: { x: 81.5, y: 58.5 },
      thrusters: { x: 19.5, y: 51.25 },
    },
  },
  revin: {
    name: "Revin",
    images: {
      ship: revinShipImage,
      pilot: revinPilotImage,
    },
    dialogs: {
      opening:
        "Sure, let's fight, I've got 5 seconds to spare. Oh, sorry, that might've come off rude.",
      win: "Oh wow, that was unexpected! ...Again, no offense intended.",
      lose: "Hey, you tried and that's ...something. I promise I'm not trying to be condescending.",
    },
    position: 210,
    heightPercentage: 30,
    level: 18,
    baseStatsModifiers: {
      firepower: 5,
      resilience: 3,
      speed: 1,
      precision: 1,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 3,
    },
    baseStatsTargets: [FIREPOWER, SPEED, RESILIENCE, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "star_breaker",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "star_breaker",
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 49, y: 55.75 },
      targeting: { x: 67, y: 55.75 },
      thrusters: { x: 23, y: 49 },
    },
  },
  valera: {
    name: "Valera",
    images: {
      ship: valeraShipImage,
      pilot: valeraPilotImage,
    },
    dialogs: {
      opening: "You want to fight ...me? Uh, good luck, I guess.",
      win: "Well, damn.",
      lose: "lol",
    },
    position: 250,
    heightPercentage: 30,
    level: 20,
    baseStatsModifiers: {
      firepower: 4,
      resilience: 2,
      speed: 1,
      precision: 3,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 3,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, PRECISION, ENERGY, SPEED],
    isBoss: true,
    drops: {
      platinum: {
        slug: "plasma_blade",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "plasma_blade",
      shields: null,
      thrusters: null,
      targetingSystem: "stellar_sight",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 53, y: 58.5 },
      targeting: { x: 63.5, y: 58.5 },
      thrusters: { x: 20.5, y: 51.75 },
    },
  },
};
