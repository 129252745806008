import { PartTypes, PartModel } from "types";

import firepowerImage from "assets/stat-firepower.svg";
import resilienceImage from "assets/stat-resilience.svg";
import speedImage from "assets/stat-speed.svg";
import precisionImage from "assets/stat-precision.svg";
import energyImage from "assets/stat-energy.svg";
import { COLORS } from "utils/constants";

export const PARTS: Record<PartTypes, PartModel> = {
  weapons: {
    name: "Weapon",
    stat: "firepower",
    description:
      "When your weapon are weakened in battle, your attacks become less powerful.",
    image: firepowerImage,
    color: COLORS.FIREPOWER,
  },
  shields: {
    name: "Shield",
    stat: "resilience",
    description:
      "When your shield are weakened in battle, your ship takes more damage.",
    image: resilienceImage,
    color: COLORS.RESILIENCE,
  },
  thrusters: {
    name: "Thrusters",
    stat: "speed",
    image: speedImage,
    description:
      "When your thrusters are weakened in battle, your ship slows down.",
    color: COLORS.SPEED,
  },
  targetingSystem: {
    name: "Targeting Grid",
    stat: "precision",
    image: precisionImage,
    description:
      "When your targeting grid is weakened in battle, your attacks become less accurate.",
    color: COLORS.PRECISION,
  },
  reactorCore: {
    name: "Reactor Core",
    stat: "energy",
    image: energyImage,
    description:
      "When your reactor core is weakened in battle, your pilot's special abilities are less effective.",
    color: COLORS.ENERGY,
  },
};
