import styled from "styled-components";

import { ItemsGroupModel } from "types";
import { getGroupedSupplies, getGroupedUpgrades } from "utils/stats";
import { CategoryGroup, CategoryName, CategorySeparator } from "./DataScreen";
import InventoryItem from "./InventoryItem";
import ShopItem from "./ShopItem";

interface CommonProps {
  items: string[];
  isUpgrades: boolean;
  onClick: (x: string) => void;
}

type ConditionalProps =
  | {
      isInventory: true;
      canAction?: boolean;
      isBuy?: never;
    }
  | {
      isInventory: false;
      canAction?: never;
      isBuy?: boolean;
    };

type ItemsListProps = CommonProps & ConditionalProps;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function ItemsList({
  items,
  isUpgrades,
  isInventory,
  canAction = false,
  isBuy = false,
  onClick,
}: ItemsListProps) {
  const groupedItems = isUpgrades
    ? getGroupedUpgrades(items)
    : getGroupedSupplies(items);
  return (
    <ListWrapper>
      {Object.entries(groupedItems)
        .filter(([key, value]) => {
          const groupData = value as ItemsGroupModel;
          return !!groupData.items.length;
        })
        .map(([key, value]) => {
          const groupData = value as ItemsGroupModel;
          return (
            <CategoryGroup key={key}>
              <CategoryName>
                {groupData.name} <CategorySeparator />
              </CategoryName>
              <Items>
                {groupData.items.map((itemSlug) => {
                  const ItemComponent = isInventory ? (
                    <InventoryItem
                      key={itemSlug}
                      slug={itemSlug}
                      canAction={canAction}
                      onClick={onClick}
                    />
                  ) : (
                    <ShopItem
                      key={itemSlug}
                      slug={itemSlug}
                      isBuy={isBuy}
                      onClick={onClick}
                    />
                  );
                  return ItemComponent;
                })}
              </Items>
            </CategoryGroup>
          );
        })}
    </ListWrapper>
  );
}
