import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { linkUserAccount } from "redux/actions";
import { getGameState } from "redux/selectors";
import warningImage from "assets/icon-warning.svg";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";

const AccountWarningWrapper = styled.div`
  opacity: 0.8;
`;

const WarningIcon = styled.img`
  display: block;
  height: 35px;
`;

export default function AccountWarning() {
  const dispatch = useDispatch();

  const startLinkAccountFlow = () => {
    dispatch(linkUserAccount());
  };

  const { isUserAccountLinked } = useSelector(getGameState);

  return (
    <>
      {!isUserAccountLinked && (
        <AccountWarningWrapper onClick={startLinkAccountFlow}>
          <Trigger>
            <WarningIcon src={warningImage} />
            <Tooltip>
              <TooltipName>Guest Account</TooltipName>
              <TooltipDesc>{`You are currently using a guest account. Click here to save your progress by linking your account to an email.`}</TooltipDesc>
            </Tooltip>
          </Trigger>
        </AccountWarningWrapper>
      )}
    </>
  );
}
