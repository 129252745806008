import { useEffect, useRef } from "react";
import styled from "styled-components";

import talkImage from "assets/talk.svg";
import {
  DataWrapper,
  Section,
  SectionContent,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import OnlinePlayers from "components/ui/OnlinePlayers";
import { useSelector } from "react-redux";
import { getGameState } from "redux/selectors";
import { LogMessage } from "types";

const ChatWrapper = styled.div`
  border: 1px #ffffff10 solid;
  background-color: #00000020;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const ChatDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(84vh - 210px);
  overflow-y: scroll;
`;

const Message = styled.div`
  display: flex;
  align-items: baseline;
  gap: 6px;
`;

const MessageInfo = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
`;

const MessageTime = styled.div`
  opacity: 0.75;
  font-size: 12px;
  white-space: nowrap;
`;

const MessageText = styled.div``;

const ChatLatest = styled.div``;

const DiscordLink = styled.a`
  display: block;
  border: 1px #ffffff10 solid;
  background-color: #43fffa10;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  font-size: 18px;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  color: white;
  text-align: left;
  position: static;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
  gap: 10px;

  :hover {
    background-color: #43fffa20;
  }
`;

const TalkIcon = styled.img`
  height: 20px;
`;

export default function Comms() {
  const chatRef = useRef<HTMLDivElement>(null);

  const { logMessages } = useSelector(getGameState);

  // Scroll to newest message on load, and when new messages are sent
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollIntoView({
        behavior: "auto",
        block: "end",
      });
    }
  }, [chatRef, logMessages]);

  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Active Starships</SectionTitle>
        <SectionContentScroll>
          <OnlinePlayers />
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Transmissions</SectionTitle>
        <SectionContent>
          <ChatWrapper>
            <ChatDisplay>
              {!!logMessages &&
                logMessages.map((message: LogMessage) => {
                  const messageTime = new Date(message.time).toLocaleTimeString(
                    [],
                    {
                      hour: "numeric",
                      minute: "2-digit",
                    }
                  );
                  return (
                    <Message key={messageTime}>
                      <MessageInfo>
                        <MessageTime>{messageTime}</MessageTime>
                      </MessageInfo>
                      <MessageText>{message.text}</MessageText>
                    </Message>
                  );
                })}
              <ChatLatest ref={chatRef} />
            </ChatDisplay>
          </ChatWrapper>
          <DiscordLink href="https://discord.gg/eCuSGRqGry" target="_blank">
            <TalkIcon src={talkImage} />
            Chat with others on Discord
          </DiscordLink>
        </SectionContent>
      </Section>
    </DataWrapper>
  );
}
