import React from "react";
import styled from "styled-components";

import talkImage from "assets/talk.svg";
import { floatAnimation } from "components/base/styles";
import { getNPCData } from "utils/stats";
import useDialog from "components/hooks/useDialog";
import Dialogs from "./Dialogs";

interface NPCProps {
  slug: string;
}

interface NPCWrapperProps {
  position: number;
  heightPercentage: number;
}

const NPCWrapper = styled.div<NPCWrapperProps>`
  position: absolute;
  left: ${(props) => props.position * 0.2}%;
  height: ${(props) => props.heightPercentage}%;
  z-index: 2;
  animation: ${floatAnimation} 3s ease-in infinite;
  cursor: pointer;

  &:hover {
    filter: brightness(120%);
  }
`;

const NPCImage = styled.img`
  height: 100%;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 80%;
`;

const Name = styled.div`
  color: #dddddd;
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const TalkIcon = styled.img`
  height: 20px;
  margin-left: 6px;
`;

const DialogWrapper = styled.div`
  width: 160%;
`;

export default function NPC({ slug }: NPCProps) {
  const npc = getNPCData(slug);
  const { currentStep, currentDialog, hasAction, onNextStep } = useDialog(
    npc.dialog,
    npc.isDialogOpen
  );

  return (
    <NPCWrapper
      position={npc.position}
      heightPercentage={npc.heightPercentage}
      onClick={onNextStep}
    >
      <InfoWrapper>
        <Name>
          {npc.name}
          <TalkIcon src={talkImage} />
        </Name>
        <DialogWrapper>
          <Dialogs
            avatar={npc.avatar}
            currentStep={currentStep}
            currentDialog={currentDialog}
            hasAction={hasAction}
          />
        </DialogWrapper>
      </InfoWrapper>
      <NPCImage alt={npc.name} src={npc.image} />
    </NPCWrapper>
  );
}
