import styled from "styled-components";
import { dialogNextAnimation } from "./styles";

interface DialogProps {
  avatar: string;
  dialogText: string;
  hasAction?: boolean;
}

const DialogWrapper = styled.div`
  color: #ddd;
  position: relative;
`;

const AvatarImage = styled.img`
  height: 80px;
  display: block;
  position: absolute;
  bottom: 100%;
  right: 15px;
`;

const DialogBox = styled.div`
  background-color: #000000;
  padding: 20px;
  border-radius: 10px;
  opacity: 0.95;
`;

const NextIcon = styled.div`
  width: 15px;
  height: 15px;
  background-color: #ddd;
  clip-path: polygon(0 0, 50% 50%, 0 100%);
  position: absolute;
  right: 3px;
  bottom: 10px;
  animation: ${dialogNextAnimation} 1s ease-in-out infinite;
`;

const Dialog = ({ avatar, dialogText, hasAction = false }: DialogProps) => {
  return (
    <DialogWrapper>
      <AvatarImage alt="avatar image" src={avatar} />
      <DialogBox>
        {dialogText}
        {!!hasAction && <NextIcon />}
      </DialogBox>
    </DialogWrapper>
  );
};

export default Dialog;
